import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAgencies } from '../../actions/agency';
import { getMembers } from '../../actions/member';
import { AGENCIES_GET, MEMBERS_GET } from '../../actions/types';
import TileShortcut from '../../components/TileShortcut';
import AgenciesList from '../../components/AgenciesList';
import styles from './home.module.scss';
import agencyIcon from '../../assets/images/icons/agency.svg';
import memberIcon from '../../assets/images/icons/member.svg';

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const agencyReducer = useSelector((store) => store.agencyReducer);
  const memberReducer = useSelector((store) => store.memberReducer);
  const { isLoading: agencyLoading, agencies, changedAt } = agencyReducer;
  const { isLoading: memberLoading, members } = memberReducer;
  const [agenciesByEntities, setAgenciesByEntities] = useState();

  useEffect(() => {
    getAgencies(dispatch);
    getMembers(dispatch);
  }, []);

  useEffect(() => {
    if (!agencies && !agencies?.length) return;
    const agenciesGrouped = {};
    agencies.forEach((agencie) => {
      if (agencie.entity && agenciesGrouped[agencie.entity._id]) {
        agenciesGrouped[agencie.entity._id].agencies.push(agencie);
      } else if (agencie.entity) {
        agenciesGrouped[agencie.entity._id] = { title: agencie.entity.name };
        agenciesGrouped[agencie.entity._id].agencies = [agencie];
      } else console.log(agencie);
    });
    setAgenciesByEntities(agenciesGrouped);
  }, [agencies, changedAt]);

  return (
    <>
      <div className={styles.home}>
        <div className={styles.container}>
          <ul className={styles.shorcuts}>
            <li>
              <TileShortcut
                title={'Agences'}
                count={agencies?.length}
                icon={agencyIcon}
                handleClick={() => navigate('/agencies?createAgency')}
                isLoading={agencyLoading.includes(AGENCIES_GET) || !agencies}
              />
            </li>
            <li>
              <TileShortcut
                title={'Membres'}
                count={members?.length}
                icon={memberIcon}
                handleClick={() => navigate('/members?createMember')}
                isLoading={memberLoading.includes(MEMBERS_GET) || !agencies}
              />
            </li>
          </ul>
          {(agenciesByEntities) && (
            <>
              {Object.entries(agenciesByEntities)
                .sort((a, b) => a[1].title.localeCompare(b[1].title)).map((entry) => (
                  <React.Fragment key={`entities-${entry[0]}`}>
                    <h5 className={styles.titleEntity}>{entry[1].title}</h5>
                    <div className={styles['container-agencies']}>
                      <AgenciesList changedAt={changedAt} agencies={entry[1].agencies} />
                    </div>
                  </React.Fragment>
                ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
