import React, { useEffect, useState } from 'react';
import { IoIosAdd } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import styles from './profiles.module.scss';
import { getPermissions } from '../../actions/permission';
import PermissionForm from '../../components/PermissionForm';

const MemoPermissionForm = React.memo(PermissionForm, (prevProps, nextProps) => ((
  prevProps.updatedAt && nextProps.updatedAt
  && prevProps.updatedAt === nextProps.updatedAt
)));

const Profiles = () => {
  const dispatch = useDispatch();
  const permissionReducer = useSelector((store) => store.permissionReducer);
  const { permissions } = permissionReducer;
  const [createPermission, setCreatePermission] = useState();

  useEffect(() => {
    getPermissions(dispatch);
  }, []);

  return (
    <>
      <div className={styles.profiles}>
        <div className={styles.container}>
          <div className={styles.header}>
            <h1>Les Profiles Types</h1>
            <span>
              <button onClick={() => setCreatePermission(true)}>
                <span className='icon'><IoIosAdd size={'28px'} /></span>
                <span>Ajouter un profil type</span>
              </button>
            </span>
          </div>
          {createPermission && <PermissionForm closeForm={() => setCreatePermission(false)}/>}
          {permissions && permissions.map((permission) => (
            <MemoPermissionForm key={`permission-${permission._id}`} updatedAt={permission.updatedAt} permission={permission} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Profiles;
