import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../actions/auth';
import BurgerIcon from '../BurgerIcon';
import logo from '../../assets/images/logo.png';
import styles from './navbar.module.scss';

const Navbar = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const authReducer = useSelector((store) => store.authReducer);
  const { user } = authReducer;

  function getTitlePage() {
    let title = 'Accueil';
    switch (location.pathname) {
      case '/agencies':
        title = 'Agences';
        break;
      case '/members':
        title = 'Membres';
        break;
      case '/profiles':
        title = 'Profil type';
        break;
      default:
        title = 'Accueil';
        break;
    }
    return title;
  }

  return (
    <div className={isOpen ? `${styles.navbar} ${styles.opened}` : styles.navbar}>
      <div className={styles['header-tablet']}>
        <h1>{getTitlePage()}</h1>
        <BurgerIcon isOpen={isOpen} handleClick={() => setIsOpen((state) => !state)} />
      </div>
      <div className={styles.logo}>
        <img src={logo} alt="logo" />
      </div>
      {user.role === 'Admin' && (
        <ul>
          <li><NavLink to='/home' className={({ isActive }) => (isActive ? styles.active : undefined)}>Accueil</NavLink></li>
          <li><NavLink to='/agencies' className={({ isActive }) => (isActive ? styles.active : undefined)}>Agences</NavLink></li>
          <li><NavLink to='/members' className={({ isActive }) => (isActive ? styles.active : undefined)}>Membres</NavLink></li>
          <li><NavLink to='/profiles' className={({ isActive }) => (isActive ? styles.active : undefined)}>Profil type</NavLink></li>
        </ul>
      )}
      <div className={styles.profile}>
        <span>
          <div className={styles.initials}>
            <span>
              {user.profile.firstName[0]}
              {user.profile.lastName[0]}
            </span>
          </div>
          <p>{user.profile.firstName} {user.profile.lastName}</p>
        </span>
        <span className={styles.logout} onClick={() => logout(dispatch)}>
          <FiLogOut />
        </span>
      </div>
    </div>
  );
};

export default Navbar;
