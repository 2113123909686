import {
  PERMISSIONS_GET,
  PERMISSION_POST,
  PERMISSION_PUT,
  PERMISSION_DELETE,
  PERMISSION_LOADING,
  PERMISSION_ERROR,
} from '../actions/types';

const DEFAULT_STATE = {
  permissions: null,
  isLoading: [],
  error: null,
  changedAt: Date.now(),
};

const permissionReducer = (state = DEFAULT_STATE, action) => {
  let updatedState = {};
  const updatePermissions = state.permissions || [];
  const permissionIndex = (updatePermissions || []).findIndex((p) => (
    p._id === action.payload?.permission?._id
  ));
  switch (action.type) {
    case PERMISSIONS_GET:
      updatedState = {
        ...state,
        permissions: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== PERMISSIONS_GET),
      };
      break;
    case PERMISSION_POST:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        permissions: [action.payload, ...state.permissions],
        isLoading: state.isLoading?.filter((d) => d !== PERMISSION_POST),
      };
      break;
    case PERMISSION_PUT:
      if (typeof permissionIndex === 'number') {
        updatePermissions[permissionIndex] = action.payload.permission;
      }
      updatedState = {
        ...state,
        changedAt: Date.now(),
        permissions: updatePermissions,
        isLoading: state.isLoading?.filter((d) => d !== PERMISSION_PUT),
      };
      break;
    case PERMISSION_DELETE:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        permissions: state.permissions.filter((permission) => (
          permission._id !== action.payload.permission._id)),
        isLoading: state.isLoading?.filter((d) => d !== PERMISSION_DELETE),
      };
      break;
    case PERMISSION_LOADING:
      updatedState = { ...state, isLoading: [...state.isLoading, action.payload] };
      break;
    case PERMISSION_ERROR:
      updatedState = { ...state };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default permissionReducer;
