import {
  getData,
  putData,
  postData,
  deleteData,
} from './index';
import {
  PERMISSIONS_GET,
  PERMISSION_POST,
  PERMISSION_PUT,
  PERMISSION_DELETE,
  PERMISSION_LOADING,
  PERMISSION_ERROR,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const getPermissions = async (dispatch) => {
  const url = '/permission';
  dispatch({
    type: PERMISSION_LOADING,
    payload: PERMISSIONS_GET,
  });
  const response = await getData(PERMISSION_ERROR, url, dispatch, true);
  if (response.data.permissions) {
    dispatch({
      type: PERMISSIONS_GET,
      payload: response.data.permissions,
    });
  }
};

export const createPermission = async (dispatch, data) => {
  const url = '/permission';
  dispatch({
    type: PERMISSION_LOADING,
    payload: PERMISSION_POST,
  });
  const response = await postData(PERMISSION_LOADING, url, dispatch, data, true);
  if (response.data?.permission) {
    dispatch({
      type: PERMISSION_POST,
      payload: response.data.permission,
    });
  }
  return response;
};

export const updatePermission = async (dispatch, data) => {
  const url = `/permission/${data._id}`;
  const { _id, ...updatedPermission } = data;
  dispatch({
    type: PERMISSION_LOADING,
    payload: PERMISSION_PUT,
  });
  const response = await putData(PERMISSION_ERROR, url, dispatch, updatedPermission, true);
  if (response.data.permission) {
    dispatch({
      type: PERMISSION_PUT,
      payload: response.data,
    });
  }
};

export const deletePermission = async (dispatch, id) => {
  const url = `/permission/${id}`;
  dispatch({
    type: PERMISSION_LOADING,
    payload: PERMISSION_DELETE,
  });
  const response = await deleteData(PERMISSION_ERROR, url, dispatch, true);
  if (response.message === 'Permission successfully removed!') {
    dispatch({
      type: PERMISSION_DELETE,
      payload: { permission: { _id: id } },
    });
  }
};
