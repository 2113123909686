import React, { useEffect } from 'react';
import { useController } from 'react-hook-form';
import getPermissionsTranslation from '../../../utils';
import styles from './members-permissions.module.scss';

const MemberPermissions = ({
  handleOpenProfilModal,
  editable,
  permission,
  control,
}) => {
  const {
    field: { onChange, value: rights },
  } = useController({
    name: 'rights',
    control,
    defaultValue: {},
    // rules: { required: true },
  });

  useEffect(() => {
    if (permission?.rights) {
      onChange(permission.rights);
    }
  }, [permission]);

  function handleChangeRight(right, bool) {
    const order = ['read', 'write', 'delete'];
    const updateRights = { ...rights };
    order.forEach((d, i) => {
      if (bool && i <= order.indexOf(right)) updateRights[d] = bool;
      else if (!bool && i >= order.indexOf(right)) updateRights[d] = bool;
      else updateRights[d] = !bool;
    });
    onChange(updateRights);
  }

  return (
    <section className={styles['section-permissions']}>
      <div className={styles.header}>
        <h5>Permissions</h5>
        <p style={editable ? { cursor: 'pointer' } : {}} onClick={editable ? handleOpenProfilModal : () => { }}>
          Profil type {permission?.name && <span>: {permission.name}</span>}
        </p>
      </div>
      {rights && (
        <ul className={styles.rights}>
          {Object.keys(rights).sort((a, b) => {
            const order = ['read', 'write', 'delete'];
            return order.indexOf(a) - order.indexOf(b);
          }).map((key) => {
            const right = rights[key];
            return (
              <li key={`right-${key}-${permission?._id || 'permission'}`}>
                <p>{getPermissionsTranslation(key)}</p>
                <div className={`${styles.status}${right ? ` ${styles.authorized}` : ''}${editable ? ` ${styles.editable}` : ''}`}>
                  {editable
                    ? <>
                      <p onClick={() => handleChangeRight(key, true)}>Oui</p>
                      <p onClick={() => handleChangeRight(key, false)}>Non</p>
                    </>
                    : <>
                      {right ? <p className={styles.allow}>Oui</p>
                        : <p className={styles.notallow}>Non</p>}
                    </>
                  }
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </section>
  );
};

export default MemberPermissions;
