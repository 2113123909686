const style = {
  container: (provided) => ({ ...provided }),
  control: (provided) => ({
    ...provided,
    boxShadow: '0 0 0 1px transparent',
    borderColor: 'transparent',
    borderWidth: '0px',
    backgroundColor: '#F4F7FE',
    minHeight: '31px',
    '&:hover': {
      borderColor: '',
    },
  }),
  placeholder: (provided) => ({ ...provided, fontSize: '14px' }),
  valueContainer: (provided) => ({
    ...provided,
  }),
  singleValue: (provided) => ({ ...provided, fontSize: '14px' }),
  multiValue: (provided) => ({ ...provided, fontSize: '14px' }),
  multiValueLabel: (provided) => ({ ...provided }),
  multiValueRemove: (provided) => ({ ...provided }),
  input: (provided) => ({
    ...provided,
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({ ...provided }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    backgroundColor: state.isSelected ? '#717A97' : '',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: state.isSelected ? '#717A97' : '#F4F7FE',
    },
  }),
  clearIndicator: (provided) => ({ ...provided }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '31px',
  }),
};

export default style;
