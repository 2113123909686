import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useController } from 'react-hook-form';
import { IoIosAdd, IoIosRemove } from 'react-icons/io';
import { HiOutlinePencil } from 'react-icons/hi';
import { BsCheck } from 'react-icons/bs';
import { AiOutlineSave, AiOutlineDelete } from 'react-icons/ai';
import getPermissionsTranslation from '../../utils';
import { updatePermission, createPermission, deletePermission } from '../../actions/permission';
import { PERMISSION_PUT, PERMISSION_POST, PERMISSION_DELETE } from '../../actions/types';
import widthModalConfirm from '../../lib/withModalConfirm';
import Loader from '../Loader';
import {
  InputText,
  ErrorField,
} from '../../lib/HooksFormFields';

import styles from './permission.module.scss';

const defaultRights = {
  delete: false,
  read: false,
  write: false,
};

const PermissionForm = ({ permission, closeForm, confirm }) => {
  const dispatch = useDispatch();
  const permissionReducer = useSelector((store) => store.permissionReducer);
  const [open, setOpen] = useState(!permission);
  const [editable, setEditable] = useState(!permission);
  const { isLoading } = permissionReducer;
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = useForm();

  const {
    field: { onChange, value: rights },
  } = useController({
    name: 'rights',
    control,
    defaultValue: defaultRights,
    // rules: { required: true },
  });

  useEffect(() => {
    if (!permission) return;
    reset({
      name: permission.name,
      rights: permission?.rights,
    });
  }, [permission]);

  useEffect(() => {
    if (!open) setEditable(false);
  }, [open]);

  useEffect(() => {
    if (editable) setOpen(true);
  }, [editable]);

  function onSubmit(data) {
    if (permission?._id) {
      updatePermission(dispatch, { ...data, _id: permission._id });
    } else {
      createPermission(dispatch, data).then((res) => {
        if (res.status === 200 && closeForm) closeForm();
      });
    }
  }

  function handleDeletePermission() {
    confirm(
      () => deletePermission(dispatch, permission._id),
      `Êtes-vous sûr de vouloir supprimer le profil type: ${permission.name}`,
    );
  }

  function handleChangeRight(right, bool) {
    const order = ['read', 'write', 'delete'];
    const updateRights = { ...rights };
    order.forEach((d, i) => {
      if (bool && i <= order.indexOf(right)) updateRights[d] = bool;
      else if (!bool && i >= order.indexOf(right)) updateRights[d] = bool;
      else updateRights[d] = !bool;
    });
    onChange(updateRights);
  }

  function getClassName(right) {
    let className = `${styles['toggle-right']}${editable ? ` ${styles.editable}` : ''}`;
    if (editable) className += ` ${styles.editable}`;
    if (right) className += ` ${styles.authorized}`;
    return className;
  }

  return (
    <div className={`${styles.permission}${(open || editable) ? ` ${styles.open}` : ''}`} key={`permission-${permission?._id || 'new'}`}>
      <div className={styles.header}>
        {!editable
          ? <>
            <span onClick={() => setEditable(true)} className={styles.toggleEdit}>
              <HiOutlinePencil />
            </span>
            <p>{permission?.name}</p>
          </>
          : <div className={styles['container-field']}>
            <InputText
              name="name"
              control={control}
              rules={{ required: 'Veuillez saisir le nom du profil type' }}
              placeholder='Nom du profil type'
              inline
            />
          </div>
        }
        <span onClick={() => setOpen((state) => !state)}>
          {open ? <IoIosRemove /> : <IoIosAdd />}
        </span>
      </div>
      {rights && (
        <div className={styles.rights}>
          <ul >
            {Object.keys(rights).sort((a, b) => {
              const order = ['read', 'write', 'delete'];
              return order.indexOf(a) - order.indexOf(b);
            }).map((key) => {
              const right = rights[key];
              return (
                <li key={`right-${key}-${permission?._id || 'permission'}`}>
                  <p>{getPermissionsTranslation(key)}</p>
                  <div className={getClassName(right)}>
                    {editable
                      ? <>
                        <p className={styles.allow} onClick={() => handleChangeRight(key, true)}>
                          Oui
                        </p>
                        <p className={styles.notallow}
                          onClick={() => handleChangeRight(key, false)}>
                          Non
                        </p>
                      </>
                      : <>
                        {right ? <p className={styles.allow}>Oui</p>
                          : <p className={styles.notallow}>Non</p>}
                      </>
                    }
                  </div>
                </li>
              );
            })}
          </ul>
          {errors?.name?.message && (
            <div className={styles.errorMessage}><ErrorField message={errors.name.message} /></div>
          )}
          {editable && (
            <div className={styles.footer}>
              {permission && (
                <p className={styles.delete} onClick={() => handleDeletePermission()}>
                  {isLoading.includes(PERMISSION_DELETE)
                    ? <span className={styles.loader}><Loader size={18} className='critical' /></span>
                    : <span><AiOutlineDelete size={'20px'} /></span>
                  }
                  Supprimer le profil type
                </p>
              )}
              <button onClick={handleSubmit(onSubmit)}>
                {isLoading.includes(PERMISSION_PUT) || isLoading.includes(PERMISSION_POST)
                  ? <span className='icon loader'><Loader size={18} className='secondary' /></span>
                  : <>
                    {!isDirty
                      ? <span className='icon'><BsCheck size={'24px'} /></span>
                      : <span className='icon'><AiOutlineSave size={'20px'} /></span>
                    }
                  </>
                }
                <span>
                  {permission ? 'Valider' : 'Créer le profil type'}
                </span>
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default widthModalConfirm(PermissionForm);
