import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { animated, useSpring } from '@react-spring/web';
import styles from './modal.module.scss';

const ModalStandalone = forwardRef(({
  children,
  hideCloseButton = true,
  domNode = 'modal',
}, ref) => {
  const modalRoot = document.getElementById(domNode);
  const [open, setOpen] = useState(false);
  const [toggleAnimation, setToggleAnimation] = useState(false);

  useImperativeHandle(ref, () => ({
    toggle: () => setOpen((state) => !state),
    open: () => setOpen(true),
    close: () => setOpen(false),
  }));

  function toggle() { setOpen((state) => !state); }

  function close() {
    setToggleAnimation(false);
  }

  const spring = useSpring({
    opacity: toggleAnimation ? 1 : 0,
    onRest: (state) => {
      if (state.value.opacity === 0) toggle();
    },
  });

  useEffect(() => {
    if (!ref) console.error('ModalStandalone components need require a ref');
  }, [ref]);

  useEffect(() => {
    if (!modalRoot) {
      console.error(`Can't find the dom element (#${domNode}) where this modal should be mount \nYou should add a div with id : "${domNode}" to public/index.html
     `);
    }
  }, [modalRoot, domNode]);

  useEffect(() => {
    if (open) {
      setToggleAnimation(true);
    }
  }, [open]);

  return (
    <>
      { modalRoot
        && <>
          {createPortal(
            <>
              {(ref && open)
                && <>
                  <animated.div
                    style={{ ...spring }}
                    className={styles['overlay-modal']}
                    onClick={close}
                  />
                  <animated.div
                    style={{ ...spring }}
                    className={styles.modal}
                  >
                     {children}
                     {!hideCloseButton && <button onClick={close}>Fermer</button>}
                  </animated.div>
                </>

              }
            </>,
            modalRoot,
          )}
        </>
      }
    </>
  );
});

ModalStandalone.propTypes = {
  domNode: PropTypes.string,
};

export default ModalStandalone;
