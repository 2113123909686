import {
  getData,
  postData,
  putData,
  deleteData,
} from './index';
import {
  MEMBER_SELECT_ONE,
  MEMBERS_GET,
  MEMBER_POST,
  MEMBER_PUT,
  MEMBER_DELETE,
  MEMBER_LOADING,
  MEMBER_RESET_ERROR,
  MEMBER_ERROR,
} from './types';

export const selectMember = async (dispatch, id) => {
  const url = `/user/${id}`;
  let response;
  if (id) {
    response = await getData(MEMBER_ERROR, url, dispatch, true);
    if (response.data.user) {
      dispatch({
        type: MEMBER_SELECT_ONE,
        payload: response.data,
      });
    }
  } else {
    dispatch({
      type: MEMBER_SELECT_ONE,
      payload: null,
    });
  }
  return response;
};

// eslint-disable-next-line import/prefer-default-export
export const getMembers = async (dispatch) => {
  const url = '/user';
  dispatch({
    type: MEMBER_LOADING,
    payload: MEMBERS_GET,
  });
  const response = await getData(MEMBER_ERROR, url, dispatch, true);

  if (response.data.users) {
    dispatch({
      type: MEMBERS_GET,
      payload: response.data.users,
    });
  }
};

export const createMember = async (dispatch, data) => {
  const url = '/user';
  dispatch({
    type: MEMBER_LOADING,
    payload: MEMBER_POST,
  });
  const response = await postData(MEMBER_ERROR, url, dispatch, data, true);
  if (response.data?.user) {
    dispatch({
      type: MEMBER_POST,
      payload: response.data.user,
    });
  }
  return response;
};

export const updateMember = async (dispatch, data) => {
  const url = `/user/${data._id}`;
  const { _id, ...updatedMember } = data;
  dispatch({
    type: MEMBER_LOADING,
    payload: MEMBER_PUT,
  });
  const response = await putData(MEMBER_ERROR, url, dispatch, updatedMember, true);
  if (response.data.user) {
    dispatch({
      type: MEMBER_PUT,
      payload: response.data,
    });
  }
  return response;
};

export const deleteMember = async (dispatch, member) => {
  const url = `/user/${member._id}`;
  dispatch({
    type: MEMBER_LOADING,
    payload: MEMBER_DELETE,
  });
  const response = await deleteData(MEMBER_ERROR, url, dispatch, true);
  if (response.message === 'User successfully removed!') {
    dispatch({
      type: MEMBER_DELETE,
      payload: { member },
    });
  }
};

export const getUserList = async () => {
  const config = { headers: { Authorization: `${localStorage.getItem('token')}` } };
  const agency = localStorage.getItem('agency');
  if (agency) config.headers.agency = agency;
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/user/list`, config);
    const data = await response.json();
    return data.list;
  } catch (e) {
    return e;
  }
};

export const resetMemberError = async (dispatch) => {
  dispatch({
    type: MEMBER_RESET_ERROR,
  });
};
