import React, { useState, useRef, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { selectAgency } from '../../actions/agency';
import Navbar from '../Navbar';
import styles from './layout.module.scss';
import RightModalStandalone from '../../lib/RightModalStandalone';
import AgencyForm from '../AgencyForm';
import MemberForm from '../MemberForm';
import { selectMember } from '../../actions/member';

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const modalAgence = useRef();
  const modalMember = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const openModalAgence = useCallback(() => modalAgence.current.open(), [modalAgence]);
  const closeModalAgence = useCallback(() => modalAgence.current.close(), [modalAgence]);
  const openModalMember = useCallback(() => modalMember.current.open(), [modalMember]);
  const closeModalMember = useCallback(() => modalMember.current.close(), [modalMember]);

  function childrenWithProps() {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          openModalAgence,
          closeModalAgence,
          openModalMember,
          closeModalMember,
        });
      }
      return child;
    });
  }

  function handleCloseAgencyModal() {
    const params = Object.fromEntries(searchParams);
    delete params.createAgency;
    delete params.updateAgency;
    setSearchParams(params);
    setTimeout(() => selectAgency(dispatch, null), 400);
  }

  function handleCloseMemberModal() {
    const params = Object.fromEntries(searchParams);
    delete params.createMember;
    delete params.updateMember;
    setSearchParams(params);
    setTimeout(() => selectMember(dispatch, null), 400);
  }

  return (
    <>
      <RightModalStandalone zIndex={1001} ref={modalMember} handleClose={handleCloseMemberModal}>
        <MemberForm handleClose={closeModalMember} />
      </RightModalStandalone>
      <RightModalStandalone ref={modalAgence} handleClose={handleCloseAgencyModal}>
        <AgencyForm handleClose={closeModalAgence} />
      </RightModalStandalone>
      <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className={isOpen ? `${styles.layout} ${styles['nav-opened']}` : styles.layout}>
        {childrenWithProps()}
      </div>
    </>
  );
};

export default Layout;
