import React, { useEffect, useState, useMemo } from 'react';
import { NavLink, useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosAdd } from 'react-icons/io';
import { IoChevronDownSharp, IoChevronUpSharp } from 'react-icons/io5';
import { BsSearch } from 'react-icons/bs';
import memberIcon from '../../assets/images/icons/member.svg';
import TileShortcut from '../../components/TileShortcut';
import { getMembers, selectMember } from '../../actions/member';
import { setUserAgency } from '../../actions/auth';
import { MEMBERS_GET } from '../../actions/types';
import MembersList from '../../components/MembersList';
import styles from './members.module.scss';

const Members = ({
  openModalMember,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { authReducer, memberReducer } = useSelector((store) => store);
  const { members, changedAt, isLoading: memberLoading } = memberReducer;
  const { user: { role, agencies = [] }, selectedAgency: userSelectedAgency } = authReducer;
  const [searchValue, setSearchValue] = useState('');
  const [selectAgencyOpen, setSelectAgencyOpen] = useState(false);

  const filteredMembers = useMemo(() => {
    let arr = members?.filter((member) => {
      const memberAgenciesId = member.agencies.map((agency) => agency?._id);
      return ((agencies.length > 0 && role === 'Owner' && memberAgenciesId.includes(userSelectedAgency?._id))
        || (role !== 'Owner'));
    });
    if (searchValue.length > 2) {
      arr = arr.filter((member) => (
        (member.profile.firstName.toLowerCase().includes(searchValue.toLowerCase())
          || member.profile.lastName.toLowerCase().includes(searchValue.toLowerCase()))
      ));
    }
    return arr;
  }, [searchValue, members, userSelectedAgency, changedAt]);

  // GET MEMBER
  useEffect(() => {
    getMembers(dispatch);
  }, []);

  // HANDLE CLOSE DROPDOWN SELECT AGENCY
  useEffect(() => {
    const closeSelectDropdown = () => setSelectAgencyOpen(false);
    if (selectAgencyOpen) {
      window.addEventListener('click', closeSelectDropdown);
    }
    return () => {
      window.removeEventListener('click', closeSelectDropdown);
    };
  }, [selectAgencyOpen]);

  // OPEN MODAL FOR CREATE MEMBER
  useEffect(() => {
    if (searchParams.get('createMember') !== null) {
      openModalMember();
    }
  }, [searchParams]);

  // SELECT MEMBER AND OPEN MODAL FOR UPDATE MEMBER
  useEffect(() => {
    if (searchParams.get('updateMember') !== null && (members && members.length)) {
      const isMember = searchParams.get('updateMember');
      if (isMember) {
        selectMember(dispatch, isMember);
        openModalMember();
      }
    }
  }, [searchParams, members]);

  return (
    <>
      <div className={styles.members}>
        <div className={styles.container}>
          <div className={styles.header}>
            {role === 'Owner' && (
              <div className={styles.headerAdmin}>
                {userSelectedAgency && (
                  <div>
                    <h1>{userSelectedAgency.entity.name}</h1>
                    {agencies.length > 1 ? (
                      <div className={`${styles.selectAgency}${selectAgencyOpen ? ` ${styles.opened}` : ''}`}>
                        <h3
                          onClick={() => setSelectAgencyOpen((state) => !state)}
                        >
                          {userSelectedAgency.name}
                          {selectAgencyOpen ? <IoChevronUpSharp /> : <IoChevronDownSharp />}
                        </h3>
                        <div className={styles.opts}>
                          <ul>
                            {agencies.filter((agency) => agency._id !== userSelectedAgency._id)
                              .map((agency) => (
                                <li
                                  key={`opt-agency-${agency._id}`}
                                  onClick={() => setUserAgency(dispatch, agency)}
                                ><span>{agency.entity.name}</span> - {agency.name}</li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    ) : <h3>{userSelectedAgency.name}</h3>}
                  </div>
                )
                }
                <TileShortcut
                  title={'Membres'}
                  count={filteredMembers?.length}
                  icon={memberIcon}
                  handleClick={() => navigate('/members?createMember')}
                  isLoading={memberLoading.includes(MEMBERS_GET)}
                />
              </div>
            )}
            <h1>Les membres</h1>
            <span className={styles.right}>
              <div className={styles.search}>
                <input
                  className={styles.input}
                  name='search'
                  type='text'
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder='Rechercher'
                />
                <BsSearch />
              </div>
              {role === 'Admin' && (
                <NavLink to='/members?createMember'>
                  <button>
                    <span className='icon'><IoIosAdd size={'28px'} /></span>
                    <span>Ajouter un membre</span>
                  </button>
                </NavLink>
              )}
            </span>
          </div>
          {(filteredMembers && filteredMembers.length) ? (
            <MembersList changedAt={changedAt} members={filteredMembers} />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Members;
