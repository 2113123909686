import React, { useState, useEffect } from 'react';
import { useController } from 'react-hook-form';
import styles from './timepicker.module.scss';

const defaultTimes = ['08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00',
  '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30'];

const Timepicker = ({
  times = defaultTimes,
  name,
  minValue,
  maxValue,
  defaultValue,
  placeholder,
  control,
  rules = {},
}) => {
  const [open, setOpen] = useState(false);

  const {
    field: { onChange, value },
  } = useController({
    control,
    name,
    defaultValue,
    rules,
  });

  // HANDLE CLOSE MODAL WHEN CLICK OUTSITE
  // EXCEPT IT TARGET ELEMENT HAS ATTRIBUTE
  // data-close-modal='false'
  useEffect(() => {
    const closeDropdown = (e) => {
      if (open && !e.target.closest("[data-close-timepicker='false']")) {
        setOpen(false);
      }
    };
    document.addEventListener('click', closeDropdown);
    return () => {
      document.removeEventListener('click', closeDropdown);
    };
  }, [open]);

  function getClassName(hour) {
    let className = styles.hour;
    if (value === hour) className += ` ${styles.selected}`;
    if (minValue) {
      const findMinIndex = times.find((d) => d === minValue);
      const findHourIndex = times.find((d) => d === hour);
      if (findHourIndex < findMinIndex) className += ` ${styles.disabled}`;
    }
    if (maxValue) {
      const findMaxIndex = times.find((d) => d === maxValue);
      const findHourIndex = times.find((d) => d === hour);
      if (findHourIndex > findMaxIndex) className += ` ${styles.disabled}`;
    }
    return className;
  }

  return (
    <div className={styles.timepicker} >
      <p onClick={() => setOpen(true)}>
        {value || <span className={styles.placeholder}>{placeholder}</span>}
      </p>
      {open && (
        <div className={styles.dropdown} data-close-timepicker='false'>
          <div className={styles.options}>
            {times.map((hour) => (
              <p
                key={`opt-${hour}`}
                className={getClassName(hour)}
                onClick={() => {
                  onChange(hour); setOpen(false);
                }}
              >
                {hour}
              </p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Timepicker;
