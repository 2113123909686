import React from 'react';
import PropTypes from 'prop-types';
import { IoIosAdd } from 'react-icons/io';
import { Skeleton } from '@mui/material';
import AnimatedButton from '../AnimatedButton';
import styles from './tile-shortcut.module.scss';

const TileShortcut = ({
  title,
  count,
  icon,
  handleClick,
  isLoading,
}) => (
  <>
    {isLoading
      ? <div className={styles.loading}><Skeleton variant="rectangle" width={300} height={105} /></div>
      : <div className={styles['tile-shortcut']}>
        <div className={styles.col}>
          <div>
            {icon && (
              <div className={styles.icon}>
                <img src={icon} />
              </div>
            )}
            <h4>{title}</h4>
          </div>
        </div>
        <div className={styles.col}>
          <p>{count} existantes</p>
          {handleClick && (
            <AnimatedButton>
              <button onClick={handleClick}>
                <IoIosAdd />
              </button>
            </AnimatedButton>
          )}
        </div>
      </div>
    }
  </>
);

TileShortcut.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number,
  icon: PropTypes.any,
  isLoading: PropTypes.bool,
};

export default TileShortcut;
