import React, { useState, useEffect } from 'react';
import { orderBy } from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import List from '../../lib/List';
import folder from '../../assets/images/icons/folder.svg';
import member from '../../assets/images/icons/member.svg';
import styles from './agencies-list.module.scss';

const AgenciesList = ({ agencies, changedAt }) => {
  const defaultSort = { key: 'name', order: 'asc' };
  const defaultOrder = ['name', 'location'];

  const navigate = useNavigate();
  const [sortedAgencies, setSortedAgencies] = useState();
  const [initSelectedAgency, setInitSelectedAgency] = useState();
  const [searchParams] = useSearchParams();

  // INIT AGENCIES AND DETERMINE SELECTED ITEM IF
  // THERE SHOULD BE ONE
  useEffect(() => {
    const arrSortedAgencies = orderBy(agencies, defaultOrder, [defaultSort.order]);
    setSortedAgencies(arrSortedAgencies);
  }, [agencies, changedAt]);

  useEffect(() => {
    if (!sortedAgencies) return;
    const paramAgencyId = Object.fromEntries(searchParams).updateAgency;
    const findIndexAgency = sortedAgencies.findIndex((agency) => agency._id === paramAgencyId);
    setInitSelectedAgency(typeof findIndexAgency === 'number' ? findIndexAgency : null);
  }, [sortedAgencies, searchParams]);

  function handleSelectRow(item) {
    navigate(`/agencies?updateAgency=${item._id}`);
  }

  const CellCases = ({ casesCount }) => (
    <div className={`${styles.cellCount}`}>
      <p>{casesCount}</p>
      <img src={folder} alt="dossiers en cours" />
    </div>
  );

  const CellMember = ({ usersCount }) => (
    <div className={`${styles.cellCount}`}>
      <p>{usersCount}</p>
      <img src={member} alt="nombre de membres" />
    </div>
  );

  return (
    <div className={styles.agenciesList}>
      {(initSelectedAgency !== undefined && sortedAgencies) && <List
        selectedItem={initSelectedAgency}
        classNameRow={styles.row}
        defaultSort={defaultSort}
        defaultOrder={defaultOrder}
        col={[
          { title: 'Nom de l\'agence', itemProperty: 'name', sortable: true },
          { title: 'Localisation', itemProperty: 'address.city', sortable: true },
          {
            title: 'Dossiers en cours',
            itemProperty: 'casesCount',
            component: CellCases,
            sortable: true,
          },
          {
            title: 'Nombre de membre',
            itemProperty: 'usersCount',
            component: CellMember,
            sortable: true,
          },
        ]}
        items={sortedAgencies}
        handleClickRow={handleSelectRow}
      >
      </List>}
    </div>
  );
};

export default AgenciesList;
