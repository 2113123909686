import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import styles from '../styled-select.module.scss';
import styleJs from '../style';

const StyledSelect = ({
  name,
  control,
  rules,
  widthLabel = null,
  inline = false,
  placeholder = '',
  label = null,
  icon = null,
  ...props
}) => (
    <Controller
      name={name}
      control={control}
      rules={rules}
      onFocus={() => {
        console.log('controller focus');
      }}
      render={({
        field,
      }) => (
          <div className={inline ? `${styles['container-select']} ${styles.inline}` : `${styles['container-select']}`}>
            { label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
            <Select
              {...field}
              {...props}
              components={{
                DropdownIndicator: (p) => (icon || components.DropdownIndicator(p)),
              }}
              className={`${styles.select}`}
              placeholder={placeholder}
              styles={styleJs}
            />
          </div>
      )}
    />
);

StyledSelect.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  widthLabel: PropTypes.string,
  inline: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default StyledSelect;
