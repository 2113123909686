import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { BiLock } from 'react-icons/bi';
import { signIn } from '../../actions/auth';
import SignInForm from '../../components/AuthForm/SignInForm';
import styles from './signin.module.scss';

const SignIn = () => {
  const authReducer = useSelector((d) => d.authReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authReducer.user?._id && authReducer.user?.role === 'Admin') navigate('/home');
    else if (authReducer.user?._id && authReducer.user?.role === 'Owner') navigate('/members');
  }, [authReducer.user]);

  function submit(data) {
    signIn(dispatch, data);
  }

  return (
    <div className={styles.signin}>
      <div className={`${styles.col} hide-on-mobile`}></div>
      <div className={styles.col}>
        <h4>Bienvenue sur votre admin</h4>
        <div className={styles['container-form']}>
          <h1>Connexion</h1>
          <SignInForm
            submit={submit}
            errorMessage={authReducer.error}
          />
          <div className={styles.center}>
            <NavLink to="forgot-password">
              <BiLock size={'18px'}/>Mot de passe oublié ?
            </NavLink>
          </div>
        </div>
      </div>

    </div>
  );
};

export default SignIn;
