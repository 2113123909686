import {
  MEMBER_POST,
  MEMBER_DELETE,
  AGENCIES_SELECT_ONE,
  AGENCIES_GET,
  AGENCY_POST,
  AGENCY_PUT,
  AGENCY_DELETE,
  AGENCY_LOADING,
  AGENCY_ERROR,
} from '../actions/types';

const DEFAULT_STATE = {
  agencies: null,
  selectedAgency: null,
  isLoading: [],
  changedAt: Date.now(),
  error: null,
};

const agencyReducer = (state = DEFAULT_STATE, action) => {
  let updatedState = {};
  let updateAgencies = state.agencies || [];
  let updateAgency = {};
  let updateSelectedAgency = state.selectedAgency;
  const agencyIndex = (updateAgencies || []).findIndex((p) => (
    p._id === action.payload?.agency?._id
  ));
  switch (action.type) {
    case MEMBER_DELETE:
      // update agency usersCount when an user is deleted
      // eslint-disable-next-line no-case-declarations
      const agenciesMemberDeleted = action.payload.member.agencies.map((d) => (d._id || d));
      updateAgencies = updateAgencies.map((agency) => {
        const obj = JSON.parse(JSON.stringify(agency));
        if (agenciesMemberDeleted.includes(agency._id)) {
          obj.usersCount -= 1;
          obj.updatedAt = new Date();
        }
        return obj;
      });
      updatedState = {
        ...state,
        changedAt: Date.now(),
        agencies: updateAgencies,
      };
      break;
    case MEMBER_POST:
      // update agency usersCount when an user is created
      // eslint-disable-next-line no-case-declarations
      const agenciesMemberCreated = action.payload.agencies.map((d) => (d._id || d));
      updateAgencies = updateAgencies.map((agency) => {
        const obj = JSON.parse(JSON.stringify(agency));
        if (agenciesMemberCreated.includes(agency._id)) {
          obj.usersCount += 1;
          obj.updatedAt = new Date();
        }
        return obj;
      });
      updatedState = {
        ...state,
        changedAt: Date.now(),
        agencies: updateAgencies,
      };
      break;
    case AGENCIES_SELECT_ONE:
      updatedState = {
        ...state,
        selectedAgency: action.payload?.agency,
      };
      break;
    case AGENCIES_GET:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        agencies: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== AGENCIES_GET),
      };
      break;
    case AGENCY_POST:
      updateAgency = {
        ...action.payload.agency,
        usersCount: action.payload.agency.members.length,
      };
      updatedState = {
        ...state,
        changedAt: Date.now(),
        selectedAgency: updateAgency,
        agencies: [updateAgency, ...state.agencies],
        isLoading: state.isLoading?.filter((d) => d !== AGENCY_POST),
      };
      break;
    case AGENCY_DELETE:
      updatedState = {
        ...state,
        selectedAgency: null,
        agencies: state.agencies.filter((agency) => agency._id !== action.payload.agency._id),
        isLoading: state.isLoading?.filter((d) => d !== AGENCY_DELETE),
      };
      break;
    case AGENCY_PUT:
      if (typeof agencyIndex === 'number') {
        updateAgencies[agencyIndex] = {
          ...action.payload.agency,
          usersCount: action.payload.agency.members.length,
        };
      }
      if (action.payload.agency?._id === updateSelectedAgency?._id) {
        updateSelectedAgency = action.payload.agency;
      }

      updatedState = {
        ...state,
        selectedAgency: updateSelectedAgency,
        changedAt: Date.now(),
        agencies: updateAgencies,
        isLoading: state.isLoading?.filter((d) => d !== AGENCY_PUT),
      };
      break;
    case AGENCY_LOADING:
      updatedState = { ...state, isLoading: [...state.isLoading, action.payload] };
      break;
    case AGENCY_ERROR:
      updatedState = { ...state, isLoading: [] };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default agencyReducer;
