import {
  AUTH_SIGNIN,
  AUTH_SIGNUP,
  AUTH_GET_PROFILE,
  AUTH_SET_USER_AGENCY,
  AUTH_LOADING,
  AUTH_LOGOUT,
  AUTH_RESET_ERROR,
  AUTH_ERROR,
} from '../actions/types';

const DEFAULT_STATE = {
  user: null,
  selectedAgency: null,
  isLoading: null,
  error: null,
};

let isErrorOnLogin;
let errorMessage;

const authReducer = (state = DEFAULT_STATE, action) => {
  let updatedState = {};
  switch (action.type) {
    case AUTH_SIGNIN:
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('id', action.payload.user._id);
      if (action.payload.user.role === 'Owner') {
        localStorage.setItem('agency', action.payload.user.agencies[0]._id);
      }

      updatedState = {
        ...state,
        user: action.payload.user,
        selectedAgency: action.payload.user.agencies[0],
        isLoading: false,
        error: null,
      };
      break;
    case AUTH_SIGNUP:
      updatedState = { ...state, isLoading: false, error: null };
      break;
    case AUTH_GET_PROFILE:
      if (action.payload.role === 'Owner') {
        localStorage.setItem('agency', action.payload.agencies[0]._id);
      } else if (action.payload.role === 'Admin') {
        const agency = localStorage.getItem('agency');
        if (agency) localStorage.removeItem('agency');
      }

      updatedState = {
        ...state,
        user: action.payload,
        selectedAgency: action.payload.agencies[0],
        isLoading: false,
        error: null,
      };
      break;
    case AUTH_SET_USER_AGENCY:
      if (state.user.role === 'Owner') {
        localStorage.setItem('agency', action.payload._id);
      }
      updatedState = {
        ...state,
        selectedAgency: action.payload,
      };
      break;
    case AUTH_LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('id');
      localStorage.removeItem('agency');

      updatedState = { ...state, user: null };
      break;
    case AUTH_LOADING:
      updatedState = { ...state, isLoading: false };
      break;
    case AUTH_RESET_ERROR:
      updatedState = { ...state, error: null };
      break;
    case AUTH_ERROR:
      isErrorOnLogin = action.payload.config.url.includes('auth/login');
      errorMessage = 'Vous n\'êtes pas autoriser à accéder à ce contenu';
      if (isErrorOnLogin) {
        errorMessage = 'Adresse e-mail et/ou mot de passe incorrects.';
      } else if (!isErrorOnLogin && action.payload.data.error === 'That email address is already in use.') {
        errorMessage = 'Un compte est déjà associé à cette adresse e-mail.';
      } else if (!isErrorOnLogin && action.payload.data.error === 'Your request could not be processed as entered. Please try again.') {
        errorMessage = 'Aucun compte n\'est déjà associé à cette adresse e-mail.';
      }
      updatedState = { ...state, error: errorMessage };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default authReducer;
