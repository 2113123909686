import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { HiOutlinePencil } from 'react-icons/hi';
import { IoIosAdd } from 'react-icons/io';
import { AiOutlineDelete } from 'react-icons/ai';
import styles from './agence-closings.module.scss';
import {
  InputText,
  DatePicker,
  ErrorField,
} from '../../../lib/HooksFormFields';

import withModalConfirm from '../../../lib/withModalConfirm';
import Timepicker from './Timepicker';

const defaultTimes = ['08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00',
  '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00'];

const AgenceTags = ({
  control,
  editable,
  errors,
  confirm,
}) => {
  const [openFieldClosing, setOpenFieldClosing] = useState();
  const {
    control: control2,
    handleSubmit,
    // setValue,
    reset,
    formState: { errors: errorsInput },
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'closingDays', // unique name for your Field Array
  });

  function onSubmit(data) {
    reset({
      newClosing: '',
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
    });
    append({
      name: data.newClosing,
      startDate: data.startDate,
      startTime: data.startTime,
      endDate: data.endDate,
      endTime: data.endTime,
    }, { shouldFocus: false });
  }

  function handlePressEnter(e) {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  function handleDelete(index, tag) {
    confirm(() => {
      remove(index);
    }, `Êtes-vous sûr de vouloir supprimer le tag: ${tag}, de cette agence ?`);
  }

  return (
    <section className={styles['section-closings']}>
      <h5>Fermetures</h5>
      {editable && (
        <div className={styles['create-closing']} onKeyDown={handlePressEnter}>
          <InputText
            name={'newClosing'}
            control={control2}
            rules={{ required: 'Veuillez saisir le nom de la période' }}
            placeholder='Créer une nouvelle période'
          />
          <button onClick={handleSubmit(onSubmit)}>
            <span className='icon'><IoIosAdd size={'28px'} /></span>
            <span>Ajouter</span>
          </button>
          <div className={styles.timing}>
            <DatePicker
              name={'startDate'}
              control={control2}
              dateFormat='dd/MM/yy'
              placeholder='Date début'
              className='discreet'
              requiredMessage='Veuillez saisir le date de début'
              noIcon
              inline
            />
            <Timepicker
              name={'startTime'}
              times={defaultTimes.slice(0, defaultTimes.length - 1)}
              placeholder={defaultTimes[0]}
              control={control2}
              rules={{ required: 'Veuillez saisir l\'heure de début' }}
            />
            <DatePicker
              name={'endDate'}
              control={control2}
              dateFormat='dd/MM/yy'
              placeholder='Date fin'
              className='discreet'
              requiredMessage='Veuillez saisir le date de fin'
              noIcon
              inline
            />
            <Timepicker
              name='endTime'
              times={defaultTimes.slice(1, defaultTimes.length)}
              placeholder={defaultTimes[1]}
              // minValuegetMinTime()}
              control={control2}
              rules={{ required: 'Veuillez saisir l\'heure de fin' }}
            />
          </div>
          {!!Object.keys(errorsInput).length && (
            ['newClosing', 'startDate', 'startTime', 'endDate', 'endTime'].map((key) => (
              errorsInput?.[key] ? <div className={styles.errorMessage}>
                <ErrorField key={`error-${key}`} message={errorsInput?.[key].message} />
              </div> : null
            )).filter((d) => d)[0]
          )}
        </div>
      )}
      <div className={styles.closings}>
        {fields.map((field, index) => (
          <div className={styles['container-field']} key={field.id}>
            <div>
              <InputText
                name={`closingDays.${index}.name`}
                control={control}
                disabled={!editable}
                required
                requiredMessage="Veuillez saisir votre l'agence"
              />
              {editable && (
                <>
                  <span className={styles.edit}
                    onClick={() => setOpenFieldClosing((state) => (state === index ? null : index))}
                  >
                    <HiOutlinePencil />
                  </span>
                  <span className={styles.delete} onClick={() => handleDelete(index, field.value)}>
                    <AiOutlineDelete />
                  </span>
                </>
              )}
            </div>
            <div style={openFieldClosing === index ? {} : { display: 'none' }} className={styles.timing}>
              <DatePicker
                name={`closingDays.${index}.startDate`}
                control={control}
                dateFormat='dd/MM/yy'
                placeholder='Date début'
                className='discreet'
                noIcon
                inline
              />
              <Timepicker
                name={`closingDays.${index}.startTime`}
                times={defaultTimes.slice(0, defaultTimes.length - 1)}
                placeholder={defaultTimes[0]}
                control={control}
              />
              <DatePicker
                name={`closingDays.${index}.endDate`}
                control={control}
                dateFormat='dd/MM/yy'
                placeholder='Date fin'
                className='discreet'
                noIcon
                inline
              />
              <Timepicker
                name={`closingDays.${index}.endTime`}
                times={defaultTimes.slice(1, defaultTimes.length)}
                placeholder={defaultTimes[1]}
                // minValuegetMinTime()}
                control={control}
              />
            </div>
          </div>
        ))}
      </div>
      {errors.tags && <ErrorField message={errors.tags.message} />}
    </section>
  );
};

export default withModalConfirm(AgenceTags);
