import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useController } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosAdd, IoIosRemove } from 'react-icons/io';
import { BsCheck } from 'react-icons/bs';
import { getPermissions } from '../../../actions/permission';
import ModalStandalone from '../../../lib/ModalStandalone';
import styles from './members-permissions.module.scss';

const MemberProfilModal = ({ control }, ref) => {
  const dispatch = useDispatch();
  const [typesOpened, setTypesOpened] = useState([]);
  const permissionReducer = useSelector((store) => store.permissionReducer);
  const { permissions } = permissionReducer;
  const modalRef = useRef();

  const {
    field: { onChange, value },
  } = useController({
    name: 'permission',
    control,
    // rules: { required: true },
  });

  // GET MEMBERS
  useEffect(() => {
    if (!permissions) getPermissions(dispatch);
    else if (permissions) {
      // console.log(permissions);
    }
  }, [permissions]);

  useImperativeHandle(ref, () => ({
    toggle: () => modalRef.current.toggle(),
    open: () => modalRef.current.open(),
    close: () => modalRef.current.close(),
  }));

  function toggleProfil(profil) {
    if (typesOpened.includes(profil)) {
      setTypesOpened((state) => state.filter((d) => d !== profil));
    } else {
      setTypesOpened((state) => [...state, profil]);
    }
  }

  function getClassName(profil, id) {
    let className = styles.permission;
    if (typesOpened.includes(profil)) className += ` ${styles.opened}`;
    if (value?._id === id) className += ` ${styles.selected}`;
    return className;
  }

  return (
    <ModalStandalone ref={modalRef}>
      <div className={styles.container}>
        <div className={styles.close} onClick={() => modalRef.current.toggle()}>
          <IoIosAdd />
        </div>
        <h3>Choisir un profil type</h3>
        {permissions && (
          <ul className={styles.permissions}>
            {permissions.map((permission) => (
              <li
                key={`permission-${permission._id}`}
                onClick={() => onChange(permission)}
                className={getClassName(permission.name, permission._id)}
              >
                <div
                  className={styles.header}
                >
                  <p>{permission.name}</p>
                  <span onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    toggleProfil(permission.name);
                  }}>
                    {typesOpened.includes(permission.name) ? <IoIosRemove /> : <IoIosAdd />}
                  </span>
                </div>
                <ul className={styles.rights}>
                  {Object.keys(permission.rights).map((key) => {
                    const right = permission.rights[key];
                    return (
                      <li className={styles.right} key={`right-${key}-${permission._id}`}>
                        <p>{key}</p>
                        <p>{right ? 'Oui' : 'Non'}</p>
                      </li>
                    );
                  })}
                </ul>
              </li>
            ))}
          </ul>
        )}
        <div className='center'>
          <button className={!value ? 'disabled' : ''} onClick={() => modalRef.current.toggle()}>
            <span className='icon'><BsCheck size={'24px'} /></span>
            <span>Valider</span>
          </button>
        </div>
      </div>
    </ModalStandalone>
  );
};

export default forwardRef(MemberProfilModal);
