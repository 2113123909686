import React, { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getUserList } from '../../actions/member';
import List from '../../lib/List';
import styles from './members-list.module.scss';

const MembersList = ({ members, changedAt }) => {
  const defaultSort = { key: 'profile.firstName', order: 'asc' };
  const defaultOrder = ['profile.firstName', 'profile.lastName'];

  const navigate = useNavigate();
  const [sortedMembers, setSortedMembers] = useState();
  const [initSelectedMember, setInitSelectedMember] = useState();
  const [searchParams] = useSearchParams();

  // INIT MEMBERS AND DETERMINE SELECTED ITEM IF
  // THERE SHOULD BE ONE
  useEffect(() => {
    async function setMembers() {
      const list = await getUserList();
      let arrSortedMembers = orderBy(members, defaultOrder, [defaultSort.order]);
      // SET ROLE WITH VALUE AND LABEL
      arrSortedMembers = arrSortedMembers.map((member) => {
        const role = list?.roles.find((r) => member.role === r.value);
        const obj = { ...member };
        if (role) obj.role = role;
        return obj;
      });
      setSortedMembers(arrSortedMembers);
    }
    setMembers();
  }, [members, changedAt]);

  useEffect(() => {
    if (!sortedMembers) return;
    const paramMemberId = Object.fromEntries(searchParams).updateMember;
    const findIndexMember = sortedMembers.findIndex((mumber) => mumber._id === paramMemberId);
    setInitSelectedMember(typeof findIndexMember === 'number' ? findIndexMember : null);
  }, [sortedMembers, searchParams]);

  function handleSelectRow(item) {
    navigate(`/members?updateMember=${item._id}`);
  }

  const CellLastName = ({ profile }) => (
    <div className={styles.lastName}>
      <p>{profile.lastName}<span>&nbsp;{profile.firstName}</span></p>
    </div>
  );

  const CellFirstName = ({ profile }) => (
    <div className={styles.firstName}>
      <p>{profile.firstName}</p>
    </div>
  );

  const CellJob = ({ profile }) => (
    <div className={styles.job}>
      <p>
        <label>Poste&nbsp;</label>
        {profile.job}
      </p>
    </div>
  );

  const CellAgencies = ({ agencies, ...member }) => (
    <div className={styles.agencies}>
      <p>
        <label>Agences&nbsp;</label>
        {agencies.map((agency, i) => <span key={`agency-${agency._id}-${member._id}`}>{agency.name}{i < (agencies.length - 1) ? ', ' : ''}</span>)}
      </p>
    </div>
  );

  const CellStatut = ({ role }) => (
    <div className={`${styles.role} ${styles[role.value]}`}>
      <p>{role.label}</p>
    </div>
  );

  return (
    <div className={styles.membersList}>
      {(initSelectedMember !== undefined && sortedMembers) && (<List
        selectedItem={initSelectedMember}
        classNameRow={styles.row}
        defaultSort={defaultSort}
        defaultOrder={defaultOrder}
        col={[
          {
            title: 'Nom',
            itemProperty: 'profile.lastName',
            sortable: true,
            component: CellLastName,
          },
          {
            title: 'Prénom',
            itemProperty: 'profile.firstName',
            sortable: true,
            component: CellFirstName,
          },
          {
            title: 'Poste',
            itemProperty: 'profile.job',
            sortable: true,
            component: CellJob,
          },
          {
            title: 'Agence',
            itemProperty: 'agencies[0].name',
            sortable: true,
            component: CellAgencies,
          },
          {
            title: 'Statut',
            itemProperty: 'role.label',
            sortable: true,
            component: CellStatut,
          },
        ]}
        items={sortedMembers}
        handleClickRow={(item) => handleSelectRow(item)}
      >
      </List>
      )}
    </div>
  );
};

export default MembersList;
