function getPermissionsTranslation(key) {
  const translate = {
    // archive: 'Archiver',
    read: 'Lire',
    write: 'Écrire',
    delete: 'Supprimer',
  };
  return translate[key];
}

// eslint-disable-next-line import/prefer-default-export
export const get = (obj = {}, path) => {
  const properties = Array.isArray(path) ? path : path.split('.');
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
};

export default getPermissionsTranslation;
