import React from 'react';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import styles from './header-list.module.scss';

const HeaderList = ({
  children,
  className,
  sortable = [],
  handleSort,
  sort,
}) => (
  <div className={`${styles.header} ${className}`}>
    {children.map((child, i) => (
      <div
        key={`cell-${i}`}
        className={`${styles.cell}${sortable[i] === sort.key ? ` ${styles.selected}` : ''}`}
        style={sortable[i] ? { cursor: 'pointer' } : {}}
        onClick={sortable[i] ? () => handleSort(sortable[i]) : () => { }}
      >
        {child}
        {sortable[i] && (
          <div className={`${styles.sort} ${sortable[i] === sort.key ? ` ${styles[sort.order]}` : ''}`} >
            <IoMdArrowDropup />
            <IoMdArrowDropdown />
          </div>
        )}
      </div>
    ))}
  </div>
);

export default HeaderList;
