import axios from 'axios';
import { AUTH_ERROR } from './types';

const API_URL = process.env.REACT_APP_API_URL;

export function errorHandler(dispatch, error, type) {
  console.log('Error type: ', type);

  if (type !== AUTH_ERROR && error.response !== undefined && error.response.status === 401) {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
    }

    if (localStorage.getItem('id')) {
      localStorage.removeItem('id');
    }
    window.location.href = '/';
  }
  if (type) {
    dispatch({
      type,
      payload: error.response,
    });
  }
}

export async function postData(errorType, url, dispatch, data, isAuthReq) {
  const requestUrl = API_URL + url;
  const config = {};
  let res = null;
  let error = null;
  try {
    if (isAuthReq) {
      const token = localStorage.getItem('token');
      const agency = localStorage.getItem('agency');
      config.headers = { Authorization: `${token}` };
      if (agency) config.headers.agency = agency;
    }
    res = await axios.post(requestUrl, data, config);
  } catch (err) {
    error = err;
    errorHandler(dispatch, err, errorType);
  }
  return res?.status === 200 || res?.status === 201 ? res : error;
}

export async function getData(errorType, url, dispatch, isAuthReq) {
  const requestUrl = API_URL + url;
  const config = {};
  let res = null;
  let error = null;
  if (isAuthReq) {
    const token = localStorage.getItem('token');
    const agency = localStorage.getItem('agency');
    config.headers = { Authorization: `${token}` };
    if (agency) config.headers.agency = agency;
  }
  try {
    res = await axios.get(requestUrl, config);
  } catch (err) {
    error = err;
    errorHandler(dispatch, err, errorType);
  }
  return res.status === 200 ? res : error;
}

export async function putData(errorType, url, dispatch, data, isAuthReq = true) {
  const requestUrl = API_URL + url;
  const config = {};
  let res = null;
  let error = null;
  try {
    if (isAuthReq) {
      const token = localStorage.getItem('token');
      const agency = localStorage.getItem('agency');
      config.headers = { Authorization: `${token}` };
      if (agency) config.headers.agency = agency;
    }
    res = await axios.put(requestUrl, data, config);
  } catch (err) {
    error = err;
    errorHandler(dispatch, error, errorType);
  }
  return res.status === 200 ? res : error;
}

export function deleteData(errorType, url, dispatch, isAuthReq) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;

    const config = {};

    if (isAuthReq) {
      const token = localStorage.getItem('token');
      const agency = localStorage.getItem('agency');
      config.headers = {
        Authorization: `${token}`,
      };
      if (agency) config.headers.agency = agency;
    }

    axios.delete(requestUrl, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject();
        errorHandler(dispatch, error, errorType);
      });
  });
}
