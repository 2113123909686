import {
  getData,
  putData,
  postData,
  deleteData,
} from './index';
import {
  AGENCIES_SELECT_ONE,
  AGENCIES_GET,
  AGENCY_PUT,
  AGENCY_POST,
  AGENCY_DELETE,
  AGENCY_LOADING,
  AGENCY_ERROR,
} from './types';

export const selectAgency = async (dispatch, id) => {
  const url = `/agency/${id}`;
  let response;
  if (id) {
    response = await getData(AGENCY_ERROR, url, dispatch, true);
    if (response.data.agency) {
      dispatch({
        type: AGENCIES_SELECT_ONE,
        payload: response.data,
      });
    }
  } else {
    dispatch({
      type: AGENCIES_SELECT_ONE,
      payload: null,
    });
  }
  return response;
};

export const getAgencies = async (dispatch) => {
  const url = '/agency';
  dispatch({
    type: AGENCY_LOADING,
    payload: AGENCIES_GET,
  });
  const response = await getData(AGENCY_ERROR, url, dispatch, true);

  if (response.data.agencies) {
    dispatch({
      type: AGENCIES_GET,
      payload: response.data.agencies,
    });
  }
};

export const createAgency = async (dispatch, data) => {
  const url = '/agency';
  const { _id, ...newAgency } = data;
  dispatch({
    type: AGENCY_LOADING,
    payload: AGENCY_POST,
  });
  const response = await postData(AGENCY_ERROR, url, dispatch, newAgency, true);
  if (response.data.agency) {
    dispatch({
      type: AGENCY_POST,
      payload: response.data,
    });
  }
  return response;
};

export const updateAgency = async (dispatch, data) => {
  const url = `/agency/${data._id}`;
  const { _id, ...updatedAgency } = data;
  dispatch({
    type: AGENCY_LOADING,
    payload: AGENCY_PUT,
  });
  const response = await putData(AGENCY_ERROR, url, dispatch, updatedAgency, true);
  if (response.data.agency) {
    dispatch({
      type: AGENCY_PUT,
      payload: response.data,
    });
  }
  return response;
};

export const deleteAgency = async (dispatch, id) => {
  const url = `/agency/${id}`;
  dispatch({
    type: AGENCY_LOADING,
    payload: AGENCY_DELETE,
  });
  const response = await deleteData(AGENCY_ERROR, url, dispatch, true);
  if (response.message === 'Agency successfully removed!') {
    dispatch({
      type: AGENCY_DELETE,
      payload: { agency: { _id: id } },
    });
  }
};
