import React, { useEffect } from 'react';
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
} from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { getProfileAction } from './actions/auth';

import SignIn from './pages/signin';
import ForgotPassword from './pages/forgot-password';
import ResetPassword from './pages/reset-password';

import Home from './pages/home';
import Agencies from './pages/agencies';
import Members from './pages/members';
import Profiles from './pages/profiles';
import Loader from './components/Loader';
import Layout from './components/Layout';

const Redirect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) getProfileAction(dispatch).catch(() => { navigate('/'); });
    else navigate('/');
  }, []);

  return (
    <Loader />
  );
};

const AppRoutes = () => {
  const authReducer = useSelector((state) => state.authReducer);
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      {
        authReducer.user && (
          <>
            {authReducer.user?.role === 'Admin' && (
              <>
                <Route path="/home" element={<Layout key='home'><Home /></Layout>} />
                <Route path="/agencies" element={<Layout key='agencies'><Agencies /></Layout>} />
                <Route path="/profiles" element={<Layout key='profiles'><Profiles /></Layout>} />
              </>
            )}
            <Route path="/members" element={<Layout key='members'><Members /></Layout>} />
            <Route path="*" element={<Navigate to="/members" replace />} />
          </>
        )
      }
      <Route path="*" element={<Redirect />} />
    </Routes >
  );
};

export default AppRoutes;
