import React, { useEffect, useState } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosAdd } from 'react-icons/io';
import { selectMember } from '../../actions/member';
import { getAgencies, selectAgency } from '../../actions/agency';
import AgenciesList from '../../components/AgenciesList';
import usePrevious from '../../hooks/usePrevious';
import styles from './agencies.module.scss';

const Agencies = ({
  openModalAgence,
  openModalMember,
}) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const agencyReducer = useSelector((store) => store.agencyReducer);
  const memberReducer = useSelector((store) => store.memberReducer);
  const { agencies, changedAt } = agencyReducer;
  const { members } = memberReducer;
  const prevCountAgencies = usePrevious(agencies?.length || 0);
  const [agenciesByEntities, setAgenciesByEntities] = useState();

  // GET AGENCIES
  useEffect(() => {
    getAgencies(dispatch);
  }, []);

  useEffect(() => {
    if (!agencies && !agencies?.length) return;
    const agenciesGrouped = {};
    agencies.forEach((agencie) => {
      if (agencie.entity && agenciesGrouped[agencie.entity._id]) {
        agenciesGrouped[agencie.entity._id].agencies.push(agencie);
      } else if (agencie.entity) {
        agenciesGrouped[agencie.entity._id] = { title: agencie.entity.name };
        agenciesGrouped[agencie.entity._id].agencies = [agencie];
      } else console.log(agencie);
    });
    setAgenciesByEntities(agenciesGrouped);
  }, [agencies, changedAt]);

  // OPEN MODAL FOR CREATE AGENCY
  useEffect(() => {
    if (searchParams.get('createAgency') !== null) {
      openModalAgence();
    }
    if (searchParams.get('createMember') !== null) {
      openModalMember();
    }
  }, [searchParams]);
  // SELECT AGENCY AND OPEN MODAL FOR UPDATE AGENCY
  useEffect(() => {
    if (typeof agencies?.length === 'number' && prevCountAgencies > agencies?.length) return;
    if (searchParams.get('updateAgency') !== null && (agencies && agencies.length)) {
      const idAgency = searchParams.get('updateAgency');
      if (idAgency) {
        selectAgency(dispatch, idAgency);
        openModalAgence();
      }
    }
  }, [searchParams, agencies]);

  // SELECT MEMBER AND OPEN MODAL FOR UPDATE MEMBER
  useEffect(() => {
    if (searchParams.get('updateMember') !== null && (members && members.length)) {
      const isMember = searchParams.get('updateMember');
      if (isMember) {
        selectMember(dispatch, isMember);
        openModalMember();
      }
    }
  }, [searchParams, members]);

  return (
    <>
      <div className={styles.agences}>
        <div className={styles.container}>
          <div className={styles.header}>
            <h1>Les agences</h1>
            <NavLink to='/agencies?createAgency'>
              <button>
                <span className='icon'><IoIosAdd size={'28px'} /></span>
                <span>Ajouter une agence</span>
              </button>
            </NavLink>
          </div>
          {(agenciesByEntities) && (
            <>
              {Object.entries(agenciesByEntities)
                .sort((a, b) => a[1].title.localeCompare(b[1].title)).map((entry) => (
                  <React.Fragment key={`entities-${entry[0]}`}>
                    <h5 className={styles.titleEntity}>{entry[1].title}</h5>
                    <div className={styles['container-agencies']}>
                      <AgenciesList changedAt={changedAt} agencies={entry[1].agencies} />
                    </div>
                  </React.Fragment>
                ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Agencies;
