import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BsArrowLeft,
  BsArrowRight,
  BsTelephone,
  BsEnvelope,
  BsCheck,
} from 'react-icons/bs';
import { RiLockPasswordLine } from 'react-icons/ri';
import { HiOutlinePencil } from 'react-icons/hi';
import { AiOutlineSave, AiOutlineDelete } from 'react-icons/ai';
import { useForm } from 'react-hook-form';
import {
  createMember,
  updateMember,
  deleteMember,
  getUserList,
  resetMemberError,
} from '../../actions/member';
import {
  InputText,
  InputFormatNumber,
  InputEmail,
  InputPassword,
  StyledSelect,
  ListTags,
  ErrorField,
} from '../../lib/HooksFormFields';
import { MEMBER_DELETE, MEMBER_POST, MEMBER_PUT } from '../../actions/types';
import MemberAgencies from './MemberAgencies';
import MemberPermissions from './MemberPermissions';
import MemberProfilModal from './MemberProfilModal';
import styles from './member-form.module.scss';
import withModalConfirm from '../../lib/withModalConfirm';
import Loader from '../Loader';

const MemberForm = ({ handleClose, confirm }) => {
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.authReducer);
  const memberReducer = useSelector((state) => state.memberReducer);
  const { error, selectedMember: member, isLoading } = memberReducer;
  const { user: { role: roleUser } } = authReducer;
  const modalProfileRef = useRef();
  const [editable, setEditable] = useState(false);
  const memoizedSetEditable = useCallback((bool) => setEditable(bool), []);

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    setFocus,
    reset,
    formState: { errors, isDirty },
  } = useForm();
  const [optionsJobs, setOptionsJobs] = useState([]);
  const [optionsRoles, setOptionsRoles] = useState([]);
  const permissionValue = watch('permission');

  useEffect(() => {
    getUserList().then((res) => {
      if (res.jobs) setOptionsJobs(res.jobs);
      if (res.roles && roleUser === 'Admin') setOptionsRoles(res.roles);
      else if (res.roles && roleUser !== 'Admin') {
        setOptionsRoles(res.roles.filter((d) => d.value !== 'Admin'));
      }
    });
  }, []);

  // SET VALUE ACCORDING TO MEMBER
  // BY USING RESET INSTEAD OF SETVALUE
  // ISDIRTY IS SET TO FALSE
  useEffect(() => {
    if (member && optionsJobs.length && optionsRoles.length) {
      const values = {
        firstName: member.profile.firstName,
        lastName: member.profile.lastName,
        phone: member.profile.tel,
        email: member.email,
        rights: member.rights,
        agencies: member.agencies,
        permission: member.permission,
        job: optionsJobs.find((role) => role.value === member.profile.job),
        status: member.role,
      };
      reset(values);
    }
  }, [member, optionsJobs, optionsRoles]);

  // RESET ERROR IN REDUCER MEMBER
  useEffect(() => {
    if (isDirty && error) {
      resetMemberError(dispatch);
    }
    return () => {
      if (isDirty && error) {
        resetMemberError(dispatch);
      }
    };
  }, [isDirty]);

  // FOCUS ON FIELD EMAIL IF THE EMAIL
  // IS ALREADY USED
  useEffect(() => {
    if (error && error?.includes('email')) {
      setFocus('email');
    }
  }, [error]);

  function onSubmit(data) {
    const obj = {
      email: data.email,
      agencies: (data.agencies || []).map((m) => m._id),
      permission: data?.permission?._id,
      rights: data.rights,
      role: data.status,
      profile: {
        lastName: data.lastName,
        firstName: data.firstName,
        tel: data.phone,
        job: data.job.value,
      },
    };

    if (data.password) {
      obj.password = data.password;
    }

    if (member) {
      updateMember(dispatch, { _id: member._id, ...obj }).then((res) => {
        if (res.status === 200) handleClose();
      });
    } else {
      createMember(dispatch, obj).then((res) => {
        if (res.status === 201) handleClose();
      });
    }
  }

  function handleDeleteMember() {
    confirm(() => {
      deleteMember(dispatch, member).then(() => {
        handleClose();
      });
    }, `Êtes-vous sûr de vouloir supprimer : ${member.profile.firstName} ${member.profile.lastName} ?`);
  }

  const handleOpenProfilModal = useCallback(() => {
    modalProfileRef.current.open();
  }, []);

  function handleBackward() {
    const dataReset = {
      firstName: member.profile.firstName,
      lastName: member.profile.lastName,
      phone: member.profile.tel,
      email: member.email,
      rights: member.rights,
      agencies: member.agencies,
      permission: member.permission,
      job: optionsJobs.find((role) => role.value === member.profile.job),
      status: member.role,
    };
    if (isDirty) {
      confirm(() => {
        setEditable(false);
        reset(dataReset);
      }, 'Etes vous sûr ?');
    } else {
      setEditable(false);
      reset(dataReset);
    }
  }

  function getRoleLabel() {
    if (!optionsRoles) return '';
    const role = optionsRoles.find((r) => member.role === r.value);
    return <div className={`${styles.role} ${styles[role?.value]} hide-on-mobile`}><p>{role?.label}</p></div>;
  }

  return (
    <>
      <MemberProfilModal ref={modalProfileRef} control={control} />
      <div className={styles.container}>
        <div className={styles.form}>
          {(member && !editable) && (
            <section className={styles['member-info']}>
              <div className={styles.col}>
                <div className={styles.profile}>
                  <div className={styles.mobileHeader}>
                    <p className={`${styles['close-mobile']} only-on-mobile`} onClick={() => {
                      handleClose();
                    }}>
                      <span className='icon'><BsArrowLeft size='24px' /></span>
                      <span>Revenir aux membres</span>
                    </p>
                    {getRoleLabel()}
                    <button onClick={() => setEditable(true)}>
                      <span className='icon'><HiOutlinePencil /></span>
                      <span>Editer</span>
                    </button>
                  </div>
                  <div className={styles.header}>
                    <h3>{member.profile.firstName} {member.profile.lastName}</h3>
                    {getRoleLabel()}
                    <p className={styles.job}>{member.profile.job}</p>
                  </div>
                  <div className={styles['more-info']}>
                    {member.profile.tel && (
                      <div>
                        <BsTelephone />
                        <p>{member.profile.tel}</p>
                      </div>
                    )}
                    {member.email && (
                      <div>
                        <BsEnvelope />
                        <p>{member.email}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={`${styles.col} hide-on-mobile`}>
                <button onClick={() => setEditable(true)}>
                  <span className='icon'><HiOutlinePencil /></span>
                  <span>Editer</span>
                </button>
              </div>
            </section>
          )}
          {(!member || (member && editable)) && (
            <section className={styles['member-info']}>
              <div className={styles.col}>
                {member ? (
                  <p className={`${styles['close-mobile']} only-on-mobile`} onClick={() => {
                    handleBackward();
                  }}>
                    <span className='icon'><BsArrowLeft size='24px' /></span>
                    <span>Retour</span>
                  </p>
                ) : (
                  <p className={`${styles['close-mobile']} only-on-mobile`} onClick={() => {
                    handleClose();
                  }}>
                    <span className='icon'><BsArrowLeft size='24px' /></span>
                    <span>Revenir aux membres</span>
                  </p>
                )}
                <div className={styles['container-field']}>
                  <InputText
                    name="firstName"
                    control={control}
                    rules={{ required: 'Veuillez saisir le prénom du membre' }}
                    label="Prénom"
                    inline
                  />
                </div>
                <div className={styles['container-field']}>
                  <InputText
                    name="lastName"
                    control={control}
                    rules={{ required: 'Veuillez saisir le nom du membre' }}
                    label="Nom"
                    inline
                  />
                </div>
                <div className={styles['container-field']}>
                  <StyledSelect
                    name="job"
                    control={control}
                    rules={{ required: 'Veuillez renseigner un poste' }}
                    inline
                    label="Poste"
                    options={optionsJobs}
                  />
                </div>
              </div>
              {member ? (
                <div className={`${styles.col} hide-on-mobile`}>
                  <button className={'secondary'} onClick={() => handleBackward()}>
                    <span>Retour</span>
                    <span className='icon'><BsArrowRight size='18px' /></span>
                  </button>
                </div>
              ) : (
                <div className={`${styles.col} hide-on-mobile`}>
                  <button className={'secondary'} onClick={() => {
                    handleClose();
                  }}>
                    <span>Fermer</span>
                    <span className='icon'><BsArrowRight size='18px' /></span>
                  </button>
                </div>
              )}
              <div className={styles['details-fields']}>
                <div className={styles['container-field']}>
                  <ListTags
                    name="status"
                    control={control}
                    label={'Statut'}
                    rules={{ required: 'Veuillez renseigner un statut' }}
                    tags={optionsRoles}
                  />
                </div>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <div className={styles['container-field']}>
                      <BsTelephone />
                      <InputFormatNumber
                        name="phone"
                        placeholder="Téléphone"
                        control={control}
                        // rules={{ required: 'Veuillez saisir le numéro de téléphone du membre' }}
                        format="## ## ## ## ##"
                      />
                    </div>
                  </div>
                  <div className={styles.col}>
                    <div className={styles['container-field']}>
                      <BsEnvelope />
                      <InputEmail
                        name="email"
                        control={control}
                        requiredMessage={'Veuillez saisir l\'email du membre'}
                        placeholder="Mail"
                      />
                    </div>
                  </div>
                </div>
                {member && (
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label>Changer le mot de passe</label>
                      <div className={styles['container-field']}>
                        <RiLockPasswordLine />
                        <InputPassword
                          name="password"
                          control={control}
                          newPassword
                          // placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                        />
                      </div>
                      {errors.password && (
                        <div className={styles.containerError}>
                          <ErrorField message={errors.password.message} />
                        </div>
                      )}
                    </div>
                    <div className={styles.col}>
                      <label>Confirmation du mot de passe</label>
                      <div className={styles['container-field']}>
                        <RiLockPasswordLine />
                        <InputPassword
                          name="confirm-password"
                          control={control}
                          newPassword
                          validate={(value) => {
                            let msg = true;
                            if (
                              (getValues('password') || value)
                              && getValues('password') !== value
                            ) {
                              msg = 'Les mots de passe ne sont pas identique';
                            }
                            return msg;
                          }}
                          // placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                        />
                      </div>
                      {errors['confirm-password'] && (
                        <div className={styles.containerError}>
                          <ErrorField message={errors['confirm-password'].message} />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {!!Object.keys(errors).length && (
                ['firstName', 'lastName', 'job', 'status', 'phone', 'email'].map((key) => (
                  errors?.[key] ? <div className={styles.errorMessage}>
                    <ErrorField key={`error-${key}`} message={errors?.[key].message} />
                  </div> : null
                )).filter((d) => d)[0]
              )}
              {error && (
                <div className={styles.errorMessage}>
                  <ErrorField message={error} />
                </div>
              )}
            </section>
          )}
          <MemberAgencies
            control={control}
            errors={errors}
            editable={editable}
            setEditable={memoizedSetEditable}
          />
          <MemberPermissions
            handleOpenProfilModal={handleOpenProfilModal}
            permission={permissionValue}
            control={control}
            editable={!member || (member && editable)}
          />
        </div>
        {(!member || (member && editable)) && (
          <div className={styles.footer}>
            {(member && (roleUser === 'Admin' || (roleUser === 'Owner' && member.role === 'Member'))) && (
              <p className={styles.delete} onClick={() => handleDeleteMember()}>
                {isLoading.includes(MEMBER_DELETE)
                  ? <span className={styles.loader}><Loader size={18} className='critical' /></span>
                  : <span><AiOutlineDelete size={'20px'} /></span>
                }
                {'Supprimer un membre'}
              </p>
            )}
            <button onClick={handleSubmit(onSubmit)}>
              {isLoading.includes(MEMBER_POST) || isLoading.includes(MEMBER_PUT)
                ? <span className='icon loader'><Loader size={18} className='secondary' /></span>
                : <>
                  {!isDirty
                    ? <span className='icon'><BsCheck size={'24px'} /></span>
                    : <span className='icon'><AiOutlineSave size={'20px'} /></span>
                  }
                </>
              }
              <span>
                {member ? 'Valider' : 'Créer un membre'}
              </span>
            </button>
          </div>
        )}
      </div >
    </>
  );
};

export default withModalConfirm(MemberForm);
