import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { AiOutlineDelete } from 'react-icons/ai';
import { IoIosAdd } from 'react-icons/io';
import { BsSearch } from 'react-icons/bs';
import AgencyFormContext from '../agencyFormContext';
import { getMembers } from '../../../actions/member';
import withModalConfirm from '../../../lib/withModalConfirm';

import styles from './agence-members.module.scss';
import {
  StyledSelect,
  ErrorField,
} from '../../../lib/HooksFormFields';

const AgencyMembers = ({
  control,
  errors,
  editable,
  confirm,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const memberReducer = useSelector((store) => store.memberReducer);
  const { members } = memberReducer;
  const [optionsMembers, setOptionsMembers] = useState([]);

  const listRoles = useContext(AgencyFormContext)?.listRoles;

  const {
    control: control2,
    watch,
    setValue,
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'members', // unique name for your Field Array
  });
  const selectedMember = watch('select-member');

  // GET MEMBERS
  // INIT OPTIONS
  useEffect(() => {
    if (!listRoles) return;
    if (!members) getMembers(dispatch);
    else if (members) {
      setOptionsMembers(members.map((d) => ({
        label: `${d.profile.firstName} ${d.profile.lastName}`,
        value: d._id,
        initial: `${d.profile.firstName[0]}${d.profile.lastName[0]}`,
        role: { label: listRoles.find((role) => role.value === d.role).label, value: d.role },
      })));
    }
  }, [members, listRoles]);

  useEffect(() => {
    if (selectedMember) {
      setValue('select-member', null);
      append(selectedMember);
    }
  }, [selectedMember]);

  function handleOpenModalMember() {
    const params = Object.fromEntries(searchParams);
    const queryString = Object.keys(params).map((key) => `${params[key] ? `${key}=${params[key]}` : key}`).join('&');
    const pathname = `${location.pathname}${queryString ? `?${queryString}&createMember` : '?createMember'}`;
    navigate(pathname);
  }

  function handleDelete(index, user) {
    confirm(() => {
      remove(index);
    }, `Êtes-vous sûr de vouloir supprimer : ${user}, de cette agence ?`);
  }

  function filterOptions(opts) {
    const membersId = fields.map((member) => member.value);
    const filteredOpts = opts.filter((opt) => !membersId.includes(opt.value));
    return filteredOpts.sort((a, b) => a.label.localeCompare(b.label));
  }

  return (
    <section className={styles['section-members']}>
      <h5>{fields.length} Membre{fields.length > 1 && 's'}</h5>
      {editable && (
        <div className={styles['add-members']}>
          <StyledSelect
            name="select-member"
            control={control2}
            inline
            icon={<div className={styles.icon}><BsSearch /></div>}
            placeholder='Rechercher une membre'
            options={filterOptions(optionsMembers)}
          />
          <button onClick={() => handleOpenModalMember()}>
            <span className='icon'><IoIosAdd size={'28px'} /></span>
            <span>Créer</span>
          </button>
        </div>
      )}
      <div className={styles.members}>
        {fields.map((field, index) => (
          <div key={field.id} className={styles.member}>
            <div className={styles.profile}>
              <span className={styles.initial}>
                <p>{field.initial}</p>
              </span>
              <p>{field.label} {field?.role?.label}</p>
            </div>
            {editable && (
              <span className={styles.delete} onClick={() => handleDelete(index, field.label)}>
                <AiOutlineDelete />
              </span>
            )}
          </div>
        ))}
      </div>
      {errors.tags && <ErrorField message={errors.tags.message} />}
    </section>
  );
};

export default withModalConfirm(AgencyMembers);
