import {
  MEMBER_SELECT_ONE,
  MEMBERS_GET,
  MEMBER_POST,
  MEMBER_PUT,
  MEMBER_DELETE,
  MEMBER_LOADING,
  MEMBER_RESET_ERROR,
  MEMBER_ERROR,
} from '../actions/types';

const DEFAULT_STATE = {
  selectedMember: null,
  members: null,
  isLoading: [],
  error: null,
  changedAt: Date.now(),
};

const memberReducer = (state = DEFAULT_STATE, action) => {
  let updatedState = {};
  let errorMessage = '';
  const updatedMembers = state.members || [];
  let updatedSelectedMember = state.selectedMember;
  const memberIndex = (updatedMembers || []).findIndex((p) => (
    p._id === action.payload?.user?._id
  ));
  switch (action.type) {
    case MEMBER_SELECT_ONE:
      updatedState = {
        ...state,
        selectedMember: action.payload?.user,
      };
      break;
    case MEMBERS_GET:
      updatedState = {
        ...state,
        members: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== MEMBERS_GET),
      };
      break;
    case MEMBER_POST:
      updatedState = {
        ...state,
        changedAt: Date.now(),
        members: [action.payload, ...state.members],
        isLoading: state.isLoading?.filter((d) => d !== MEMBER_POST),
      };
      break;
    case MEMBER_PUT:
      if (typeof memberIndex === 'number') {
        updatedMembers[memberIndex] = action.payload.user;
      }
      if (action.payload.user?._id === updatedSelectedMember?._id) {
        updatedSelectedMember = action.payload.user;
      }

      updatedState = {
        ...state,
        selectedMember: updatedSelectedMember,
        changedAt: Date.now(),
        members: updatedMembers,
        isLoading: state.isLoading?.filter((d) => d !== MEMBER_PUT),
      };
      break;
    case MEMBER_DELETE:
      updatedState = {
        ...state,
        selectedMember: null,
        members: state.members.filter((member) => member._id !== action.payload.member._id),
        isLoading: state.isLoading?.filter((d) => d !== MEMBER_DELETE),
      };
      break;
    case MEMBER_LOADING:
      updatedState = { ...state, isLoading: [...state.isLoading, action.payload], error: null };
      break;
    case MEMBER_RESET_ERROR:
      updatedState = { ...state, error: null };
      break;
    case MEMBER_ERROR:
      errorMessage = action.payload.data.error;
      if (errorMessage === 'That email address is already in use.') {
        errorMessage = 'Cette adresse email est déjà utilisée.';
      }
      updatedState = { ...state, error: errorMessage, isLoading: [] };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default memberReducer;
