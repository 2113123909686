import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { HiOutlinePencil } from 'react-icons/hi';
import { IoIosAdd } from 'react-icons/io';
import { AiOutlineDelete } from 'react-icons/ai';
import styles from './agence-tags.module.scss';
import {
  InputText,
  ErrorField,
} from '../../../lib/HooksFormFields';

import withModalConfirm from '../../../lib/withModalConfirm';

const AgenceTags = ({
  control,
  editable,
  errors,
  confirm,
}) => {
  const {
    control: control2,
    handleSubmit,
    setValue,
    formState: { errors: errorsInput },
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'tags', // unique name for your Field Array
  });

  function onSubmit(data) {
    setValue('new-tag', '');
    append({ value: data['new-tag'] });
  }

  function handlePressEnter(e) {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  function handleDelete(index, tag) {
    confirm(() => {
      remove(index);
    }, `Êtes-vous sûr de vouloir supprimer le tag: ${tag}, de cette agence ?`);
  }

  return (
    <section className={styles['section-tags']}>
      <h5>Tags</h5>
      <p>États du matériel</p>
      {editable && (
        <div className={styles['create-tag']} onKeyDown={handlePressEnter}>
          <InputText
            name={'new-tag'}
            control={control2}
            required
            requiredMessage="Veuillez saisir votre l'agence"
            placeholder='Créer un tag'
          />
          {errorsInput.tags && <ErrorField message={errorsInput['new-tag'].message} />}
          <button onClick={handleSubmit(onSubmit)}>
            <span className='icon'><IoIosAdd size={'28px'} /></span>
            <span>Ajouter</span>
          </button>
        </div>
      )}
      <div className={styles.tags}>
        {fields.map((field, index) => (
          <div className={styles['container-field']} key={field.id}>
            <InputText
              name={`tags.${index}.value`}
              control={control}
              disabled={!editable}
              required
              requiredMessage="Veuillez saisir votre l'agence"
            />
            {editable && (
              <>
                <span className={styles.edit}>
                  <HiOutlinePencil />
                </span>
                <span className={styles.delete} onClick={() => handleDelete(index, field.value)}>
                  <AiOutlineDelete />
                </span>
              </>
            )}
          </div>
        ))}
      </div>
      {errors.tags && <ErrorField message={errors.tags.message} />}
    </section>
  );
};

export default withModalConfirm(AgenceTags);
