import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { format, formatISO } from 'date-fns';
import { HiOutlinePencil } from 'react-icons/hi';
import { RiContactsBookLine } from 'react-icons/ri';
import { BsArrowRight, BsArrowLeft, BsCheck } from 'react-icons/bs';
import { AiOutlineSave, AiOutlineDelete } from 'react-icons/ai';
import { AGENCY_POST, AGENCY_PUT, AGENCY_DELETE } from '../../actions/types';
import {
  createAgency,
  updateAgency,
  deleteAgency,
} from '../../actions/agency';
import { get } from '../../utils';
import { getUserList } from '../../actions/member';
import AgencyFormContext from './agencyFormContext';
import Loader from '../Loader';
import AgencyTags from './AgencyTags';
import AgencyMembers from './AgencyMembers';
import AgencyClosings from './AgencyClosings';

import newAgency from '../../assets/images/new-agency.svg';
import withModalConfirm from '../../lib/withModalConfirm';

import {
  InputText,
  StyledSelect,
  Textarea,
  ErrorField,
  InputEmail,
  InputFormatNumber,
} from '../../lib/HooksFormFields';
import folder from '../../assets/images/icons/folder.svg';
import styles from './agency-form.module.scss';

const loadOptions = async () => {
  const config = { headers: { Authorization: `${localStorage.getItem('token')}` } };
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/agency/list`, config);
    const data = await response.json();
    return data.list;
  } catch (e) {
    return [];
  }
};

const AgencyForm = ({ confirm, handleClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const agencyReducer = useSelector((state) => state.agencyReducer);
  const [searchParams, setSearchParams] = useSearchParams();
  const { selectedAgency: agency, isLoading } = agencyReducer;
  const [editable, setEditable] = useState(false);
  const [optionsEntities, setOptionsEntities] = useState([]);
  const [optionsModes, setOptionsModes] = useState([]);
  const [listRoles, setListRoles] = useState();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm();

  useEffect(() => {
    loadOptions().then((res) => {
      if (res.modes) {
        setOptionsModes(res.modes.map((d) => ({
          label: d,
          value: d,
        })));
      }
      if (res.entities) {
        setOptionsEntities(res.entities.map((entity) => ({
          label: entity.name,
          value: entity._id,
        })));
      }
    });
  }, []);

  useEffect(() => {
    getUserList().then((res) => {
      setListRoles(res.roles);
    });
  }, []);

  // SET VALUE ACCORDING TO AGENCY
  // BY USING RESET INSTEAD OF SETVALUE
  // ISDIRTY IS SET TO FALSE
  useEffect(() => {
    if (!listRoles) return;
    if (agency) {
      const values = {
        name: null,
        nameSage: null,
        tags: null,
        mode: null,
        entity: null,
        members: null,
        closingDays: null,
        openingTimes: null,
        address: {},
        contactEmail: null,
        contactParc: null,
        contactTransport: null,
        telTransport: null,
      };
      Object.keys(values).forEach((d) => {
        let value;
        if (d === 'mode') {
          value = agency[d] ? { label: agency[d], value: agency[d] } : null;
        } else if (d === 'openingTimes') {
          value = agency[d] ? agency[d].replaceAll('<br/>', '\n') : '';
          console.log(value);
        } else if (d === 'tags') {
          value = (agency[d] || []).map((tag) => ({ value: tag }));
        } else if (d === 'entity') {
          value = agency[d] ? { label: agency[d].name, value: agency[d]._id } : null;
        } else if (d === 'members') {
          value = (agency[d] || []).map((user) => ({
            label: `${user.profile.firstName} ${user.profile.lastName}`,
            value: user._id,
            initial: `${user.profile.firstName[0]}${user.profile.lastName[0]}`,
            role: {
              label: listRoles.find((role) => role.value === user.role).label,
              value: user.role,
            },
          }));
        } else if (d === 'closingDays') {
          value = [];
          if (agency[d] && agency[d].length) {
            agency[d].forEach((date) => {
              const startTime = format(new Date(date.startDate), 'HH:mm');
              const endTime = format(new Date(date.endDate), 'HH:mm');
              const startDate = new Date(date.startDate);
              startDate.setMinutes(0);
              startDate.setHours(0);
              const endDate = new Date(date.endDate);
              endDate.setMinutes(0);
              endDate.setHours(0);
              value.push({
                name: date.name,
                startDate,
                startTime,
                endDate,
                endTime,
              });
            });
          }
        } else {
          value = agency[d] ? agency[d] : '';
        }
        values[d] = value;
      });
      reset(values);
    }
  }, [agency, listRoles]);

  function onSubmit(data) {
    // ajouter le champs members
    let tags = data.tags ? [...data.tags] : [];
    tags = tags.map((d) => d.value).filter((d) => d);
    const members = (data.members || []).map((d) => d.value);

    const closingDays = [];
    if (data.closingDays) {
      data.closingDays.forEach((date) => {
        const endTime = date.endTime.split(':');
        const startTime = date.startTime.split(':');
        const endDate = new Date(date.endDate);
        endDate.setHours(parseInt(endTime[0], 10));
        endDate.setMinutes(parseInt(endTime[1], 10));
        const startDate = new Date(date.startDate);
        startDate.setHours(parseInt(startTime[0], 10));
        startDate.setMinutes(parseInt(startTime[1], 10));
        closingDays.push({
          name: date.name,
          startDate: formatISO(startDate),
          endDate: formatISO(endDate),
        });
      });
    }

    if (agency) {
      updateAgency(dispatch, {
        _id: agency._id,
        name: data.name,
        nameSage: data.nameSage || '',
        mode: data.mode.label,
        entity: data.entity.value,
        address: data.address,
        openingTimes: data.openingTimes.replaceAll('\n', '<br/>'),
        closingDays,
        members,
        tags,
        contactEmail: data?.contactEmail || '',
        contactParc: data?.contactParc || '',
        contactTransport: data?.contactTransport || '',
        telTransport: data?.telTransport || '',
      }).then((res) => {
        if (res.status === 200) handleClose();
      });
    } else {
      createAgency(dispatch, {
        entity: data.entity.value,
        name: data.name,
        nameSage: data.nameSage || '',
        address: data.address,
        openingTimes: data.openingTimes.replaceAll('\n', '<br/>'),
        mode: data.mode.label,
        closingDays,
        contactEmail: data?.contactEmail || '',
        contactParc: data?.contactParc || '',
        contactTransport: data?.contactTransport || '',
        telTransport: data?.telTransport || '',
      }).then((res) => {
        if (res.status === 200) {
          const params = new URLSearchParams(searchParams);
          params.delete('createAgency');
          params.append('updateAgency', res.data.agency._id);
          setSearchParams(params);
        }
      });
    }
  }

  function handleDeleteAgency() {
    confirm(() => {
      deleteAgency(dispatch, agency._id).then(() => {
        navigate('/agencies');
        handleClose();
      });
    }, `Êtes-vous sûr de vouloir supprimer l'agence : ${agency.name} ?`);
  }

  async function handleBackward() {
    const list = await getUserList();
    let tags = agency.tags ? [...agency.tags] : [];
    tags = tags.map((value) => ({ value }));
    const mode = agency.mode ? { label: agency.mode, value: agency.mode } : null;
    const entity = agency.entity ? { label: agency.entity.name, value: agency.entity._id } : null;
    const members = (agency.users || []).map((user) => ({
      label: `${user.profile.firstName} ${user.profile.lastName}`,
      value: user._id,
      initial: `${user.profile.firstName[0]}${user.profile.lastName[0]}`,
      role: {
        label: list.roles.find((role) => role.value === user.role).label,
        value: user.role,
      },
    }));
    const dataReset = {
      name: agency.name,
      nameSage: agency?.nameSage || '',
      address: agency?.address || {},
      entity,
      mode,
      tags,
      members,
    };
    if (isDirty) {
      confirm(() => {
        setEditable(false);
        reset(dataReset);
      }, 'Etes vous sûr ?');
    } else {
      setEditable(false);
      reset(dataReset);
    }
  }

  return (
    <AgencyFormContext.Provider
      value={{ listRoles }}
    >
      <div className={styles.container}>
        <div className={styles.form}>
          {(agency && !editable) && (
            <section className={styles['agency-info']}>
              <p className={`${styles['close-mobile']} only-on-mobile`} onClick={() => {
                handleClose();
              }}>
                <span className='icon'><BsArrowLeft size='24px' /></span>
                <span>Revenir aux agences</span>
              </p>
              <div className={styles.col}>
                <div className={styles.header}>
                  <p className={styles.entity}>{agency.entity.name}</p>
                  <h3>{agency.name}</h3>
                  {agency.nameSage && <h5>{agency.nameSage}</h5>}
                  <p className={styles.localisation}>
                    {agency.address && (
                      <>
                        {`${agency.address.postal_code} ${agency.address.city}`}
                        {(agency.address.number || agency.address.street) && (
                          <>&nbsp;-&nbsp;</>
                        )}
                        {agency.address.number && `${agency.address.number}`}
                        {agency.address.street && `, ${agency.address.street}`}
                      </>
                    )}
                  </p>
                </div>
                <div className={styles['more-info']}>
                  <p className={styles.casesCount}><img src={folder} alt='dossiers en cours' /> {agency.casesCount} dossier{agency.casesCount > 1 && 's'} en cours</p>
                  <p className={styles.mode}>Type de mode: <span>{agency.mode}</span></p>
                  <p className={styles.openingTimes}>
                    Horaires:&nbsp;
                    <span dangerouslySetInnerHTML={{ __html: agency.openingTimes }}/>
                  </p>
                  <h5>
                    <RiContactsBookLine size={18} /> Contact
                  </h5>
                  <p className={styles.contact}>Contact email: <span>{agency?.contactEmail || '___'}</span></p>
                  <p className={styles.contact}>Contact parc: <span>{agency?.contactParc || '___'}</span></p>
                  <p className={styles.contact}>Contact transport: <span>{agency?.contactTransport || '___'}</span></p>
                  <p className={styles.contact}>Téléphone transport: <span>{agency?.telTransport || '___'}</span></p>
                </div>
              </div>
              <div className={styles.col}>
                <button onClick={() => setEditable(true)}>
                  <span className='icon'><HiOutlinePencil /></span>
                  <span>Editer</span>
                </button>
              </div>
            </section>
          )}
          {(!agency || (agency && editable)) && (
            <section className={styles['agency-info']}>
              {agency ? (
                <p className={`${styles['close-mobile']} only-on-mobile`} onClick={() => {
                  handleBackward();
                }}>
                  <span className='icon'><BsArrowLeft size='24px' /></span>
                  <span>Retour</span>
                </p>
              ) : (
                <p className={`${styles['close-mobile']} only-on-mobile`} onClick={() => {
                  handleClose();
                }}>
                  <span className='icon'><BsArrowLeft size='24px' /></span>
                  <span>Revenir aux agences</span>
                </p>
              )}
              <div className={styles.col}>
                <div className={styles['container-field']}>
                  <StyledSelect
                    name="entity"
                    control={control}
                    inline
                    rules={{ required: 'Veuillez renseigner un groupe' }}
                    label="Groupe"
                    options={optionsEntities}
                  />
                </div>
                <div className={styles['container-field']}>
                  <InputText
                    name="name"
                    control={control}
                    rules={{ required: 'Veuillez saisir le nom de l\'agence' }}
                    label="Nom"
                    inline
                  />
                </div>
                <div className={styles['container-field']}>
                  <InputText
                    name="nameSage"
                    control={control}
                    label="Nom Sage"
                    inline
                  />
                </div>
                <div className={styles['container-field']}>
                  <InputText
                    name="address.number"
                    control={control}
                    label="Numéro"
                    inline
                  />
                </div>
                <div className={styles['container-field']}>
                  <InputText
                    name="address.street"
                    control={control}
                    label="Rue"
                    inline
                  />
                </div>
                <div className={styles['container-field']}>
                  <InputText
                    name="address.complement"
                    control={control}
                    label="Complément"
                    inline
                  />
                </div>
                <div className={styles['container-field']}>
                  <InputText
                    name="address.postal_code"
                    control={control}
                    label="Code postal"
                    rules={{ required: 'Veuillez saisir la code postal' }}
                    inline
                  />
                </div>
                <div className={styles['container-field']}>
                  <InputText
                    name="address.city"
                    control={control}
                    rules={{ required: 'Veuillez saisir la ville' }}
                    label="Ville"
                    inline
                  />
                </div>
                <div className={styles['container-field']}>
                  <StyledSelect
                    name="mode"
                    control={control}
                    inline
                    rules={{ required: 'Veuillez renseigner un type de mode' }}
                    label="Type de mode"
                    options={optionsModes}
                  />
                </div>
                <div className={styles['container-field']}>
                  <Textarea
                    name="openingTimes"
                    control={control}
                    inline
                    label="Horaires"
                  />
                </div>
                <div className={styles['container-field']}>
                  <InputEmail
                    name="contactEmail"
                    control={control}
                    label="Contact email"
                    inline
                  />
                </div>
                <div className={styles['container-field']}>
                  <InputText
                    name="contactParc"
                    control={control}
                    label="Contact parc"
                    inline
                  />
                </div>
                <div className={styles['container-field']}>
                  <InputText
                    name="contactTransport"
                    control={control}
                    label="Contact transport"
                    inline
                  />
                </div>
                <div className={styles['container-field']}>
                  <InputFormatNumber
                    name="telTransport"
                    label="Téléphone transport"
                    inline
                    control={control}
                    format="## ## ## ## ##"
                  />
                </div>
              </div>
              {agency && (
                <div className={`${styles.col} hide-on-mobile`}>
                  <button className={'secondary'} onClick={() => handleBackward()}>
                    <span>Retour</span>
                    <span className='icon'><BsArrowRight size='18px' /></span>
                  </button>
                </div>
              )}
              {!!Object.keys(errors).length && (
                ['entity', 'name', 'address.postal_code', 'address.city', 'mode'].map((key) => (
                  (get(errors, key)) ? <div className={styles.errorMessage}>
                    <ErrorField key={`error-${key}`} message={get(errors, key)?.message} />
                  </div> : null
                )).filter((d) => d)[0]
              )}
            </section>
          )}
          {agency ? (
            <>
              <AgencyMembers
                control={control}
                errors={errors}
                editable={editable}
              />
              <AgencyTags
                control={control}
                errors={errors}
                editable={editable}
              />
              <AgencyClosings
                control={control}
                errors={errors}
                editable={editable}
              />
            </>
          ) : (
            <div className={styles.informations}>
              <p className='bold'>Une fois l&apos;
                agence créée. Vous pourrez par la suite ajouter des membres et gérer vos tags.</p>
              <img src={newAgency} alt="Création d'agence" />
            </div>
          )}
        </div>
        {
          (!agency || (agency && editable)) && (
            <div className={styles.footer}>
              {agency && (
                <p className={styles.delete} onClick={() => handleDeleteAgency()}>
                  {isLoading.includes(AGENCY_DELETE)
                    ? <span className={styles.loader}><Loader size={18} className='critical' /></span>
                    : <span><AiOutlineDelete size={'20px'} /></span>
                  }
                  {"Supprimer l'agence"}
                </p>
              )}
              <button onClick={handleSubmit(onSubmit)}>
                {isLoading.includes(AGENCY_POST) || isLoading.includes(AGENCY_PUT)
                  ? <span className='icon loader'><Loader size={18} className='secondary' /></span>
                  : <>
                    {!isDirty
                      ? <span className='icon'><BsCheck size={'24px'} /></span>
                      : <span className='icon'><AiOutlineSave size={'20px'} /></span>
                    }
                  </>
                }
                <span>
                  {agency ? 'Valider' : 'Créer mon agence'}
                </span>
              </button>
            </div>
          )
        }
      </div >
    </AgencyFormContext.Provider>
  );
};

export default withModalConfirm(AgencyForm);
