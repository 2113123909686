import React, { useState, useEffect } from 'react';
import { orderBy } from 'lodash';
import HeaderList from './HeaderList';
import ItemList from './ItemList';

const MemoItemList = React.memo(ItemList, (prevProps, nextProps) => (
  prevProps.selected === nextProps.selected
  && (
    prevProps.updatedAt && nextProps.updatedAt
    && prevProps.updatedAt === nextProps.updatedAt
  )
));

const List = ({
  selectedItem,
  items = [],
  col,
  classNameRow,
  defaultSort = { key: col[0].itemProperty, order: 'asc' },
  defaultOrder = [],
  handleClickRow = null,
}) => {
  const [sort, setSort] = useState(defaultSort);
  const [selected, setSelected] = useState();

  // INIT SELECTED ITEM
  useEffect(() => {
    if (selectedItem >= 0) setSelected(selectedItem);
    else setSelected(null);
  }, [selectedItem]);

  // HANDLE RESET SELECTED WHEN CLICK OUTSITE
  // OF ITEMS LIST
  useEffect(() => {
    const root = document.getElementById('root');
    const resetSelected = (e) => {
      if (typeof selected === 'number' && !e.target.closest("[data-item-list='true']")) {
        setSelected();
      }
    };
    root.addEventListener('click', resetSelected);
    return () => {
      root.removeEventListener('click', resetSelected);
    };
  }, [selected]);

  const fnSort = (arr) => {
    const order = new Set([sort.key, ...defaultOrder]);
    return orderBy(arr, Array.from(order), [sort.order]);
  };

  function handleSort(val) {
    setSort((state) => (
      state.key === val
        ? { key: val, order: state.order === 'asc' ? 'desc' : 'asc' }
        : { key: val, order: 'asc' }
    ));
  }

  function clickOnRow(index, item, e) {
    setSelected(index);
    if (handleClickRow) handleClickRow(item, e);
  }

  function getValue(str, obj) {
    return str.split('.').reduce((a, b) => a?.[b], obj);
  }

  return (
    <>
      <HeaderList
        className={classNameRow}
        sortable={col.filter((d) => d.sortable).map((d) => d.itemProperty)}
        handleSort={handleSort}
        sort={sort}
      >
        {col.map((d, i) => <p key={`col-${i}`}>{d.title}</p>)}
      </HeaderList>
      {fnSort(items).map((item, i) => (
        <MemoItemList updatedAt={item.updatedAt} selected={selected === i} handleClick={(e) => clickOnRow(i, item, e)} key={`row-${item._id || i}`} className={classNameRow}>
          {col.map((d, j) => (
            <React.Fragment key={`cell-${item._id || i}-${j}`}>
              <>
                {d.component
                  ? d.component({ ...item, itemProperty: d.itemProperty })
                  : <p key={`cell-${i}-${j}`}>{getValue(d.itemProperty, item)}</p>
                }
              </>
            </React.Fragment>
          ))}
        </MemoItemList>
      ))}
    </>

  );
};

export default List;
