import { combineReducers, createStore } from 'redux';
import authReducer from './authReducer';
import agencyReducer from './agencyReducer';
import memberReducer from './memberReducer';
import permissionReducer from './permissionReducer';

const rootReducer = combineReducers({
  authReducer,
  memberReducer,
  agencyReducer,
  permissionReducer,
});

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;
