export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const AUTH_SET_USER_AGENCY = 'AUTH_SET_USER_AGENCY';
export const AUTH_GET_PROFILE = 'AUTH_GET_PROFILE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';
export const AUTH_ERROR = 'AUTH_ERROR';

// ACTIONS AGENCY
export const AGENCIES_SELECT_ONE = 'AGENCIES_SELECT_ONE';
export const AGENCIES_GET = 'AGENCIES_GET';
export const AGENCY_POST = 'AGENCY_POST';
export const AGENCY_PUT = 'AGENCY_PUT';
export const AGENCY_DELETE = 'AGENCY_DELETE';
export const AGENCY_LOADING = 'AGENCY_LOADING';
export const AGENCY_ERROR = 'AGENCY_ERROR';

// ACTIONS MEMBER
export const MEMBER_SELECT_ONE = 'MEMBER_SELECT_ONE';
export const MEMBERS_GET = 'MEMBERS_GET';
export const MEMBER_POST = 'MEMBER_POST';
export const MEMBER_PUT = 'MEMBER_PUT';
export const MEMBER_DELETE = 'MEMBER_DELETE';
export const MEMBER_LOADING = 'MEMBER_LOADING';
export const MEMBER_RESET_ERROR = 'MEMBER_RESET_ERROR';
export const MEMBER_ERROR = 'MEMBER_ERROR';

// ACTIONS PERMISSIONS
export const PERMISSIONS_GET = 'PERMISSIONS_GET';
export const PERMISSION_POST = 'PERMISSION_POST';
export const PERMISSION_PUT = 'PERMISSION_PUT';
export const PERMISSION_DELETE = 'PERMISSION_DELETE';
export const PERMISSION_LOADING = 'PERMISSION_LOADING';
export const PERMISSION_ERROR = 'PERMISSION_ERROR';
