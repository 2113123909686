import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import { AiOutlineDelete } from 'react-icons/ai';
import { BsSearch } from 'react-icons/bs';
import { IoIosAdd } from 'react-icons/io';
import { getAgencies } from '../../../actions/agency';
import styles from './members-agencies.module.scss';
import withModalConfirm from '../../../lib/withModalConfirm';
import {
  StyledSelect,
  ErrorField,
} from '../../../lib/HooksFormFields';

const MemberAgencies = ({
  setEditable,
  editable,
  control,
  errors,
  confirm,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const agencyReducer = useSelector((store) => store.agencyReducer);
  const { agencies } = agencyReducer;
  const [optionsAgencies, setOptionsAgencies] = useState([]);
  const {
    control: control2,
    watch,
    setValue,
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'agencies', // unique name for your Field Array
  });
  const selectedAgency = watch('select-agency');

  // GET AGENCIES
  // INIT OPTIONS
  useEffect(() => {
    if (!agencies && location.pathname !== '/agencies') getAgencies(dispatch);
    else if (agencies) {
      setOptionsAgencies(agencies.map((d) => ({ label: d.name, value: d._id, item: d })));
    }
  }, [agencies]);

  useEffect(() => {
    if (optionsAgencies.length === 0 || !searchParams.get('updateAgency')) return;
    const findOptAgency = optionsAgencies.find((agency) => agency.value === searchParams.get('updateAgency'));
    if (findOptAgency) append(findOptAgency.item);
  }, [optionsAgencies]);

  useEffect(() => {
    if (selectedAgency) {
      setValue('select-agency', null);
      append(selectedAgency.item);
    }
  }, [selectedAgency]);

  function filterOptions(opts) {
    const agenciesId = fields.map((agency) => agency._id);
    const filteredOpts = opts.filter((opt) => !agenciesId.includes(opt.item._id));
    return filteredOpts.sort((a, b) => a.label.localeCompare(b.label));
  }

  function handleDelete(index, agence) {
    confirm(() => {
      remove(index);
    }, `Êtes-vous sûr de vouloir supprimer l'agence : ${agence}, de ce membre ?`);
  }

  return (
    <section className={styles['section-agencies']}>
      <div className={styles.header}>
        <h5>Agences</h5>
        {!editable && (
          <button onClick={() => setEditable(true)}>
            <span className='icon'><IoIosAdd size={'28px'} /></span>
          </button>
        )}
      </div>
      {editable && (
        <div className={styles['add-agencies']}>
          <StyledSelect
            name="select-agency"
            control={control2}
            inline
            icon={<div className={styles.icon}><BsSearch /></div>}
            placeholder='Rechercher une agence'
            options={filterOptions(optionsAgencies)}
          />
        </div>
      )}
      <div className={styles.agencies}>
        {fields.map((field, index) => (
          <div key={field.id} className={styles.agency}>
            <span className={styles.info}>
              <p>{field.entity.name}</p>
              <p>- {field.name}</p>
            </span>
            {editable && (
              <span className={styles.delete} onClick={() => handleDelete(index, field.name)}>
                <AiOutlineDelete />
              </span>
            )}
          </div>
        ))}
      </div>
      {errors.tags && <ErrorField message={errors.tags.message} />}
    </section>
  );
};

export default withModalConfirm(MemberAgencies);
